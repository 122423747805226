<main class="middle-content">
    <div class="content-section">
        <div class="page_title_block pL0 pR0">
            <h1 class="page_title" style="color:#26272d">User Details
            </h1>
        </div>
        <div class="custom_tabs common-tabs">
            <div class="row mb20 justify-content-center">
                <div class="col-sm-12">
                    <ul class="nav nav-tabs d-flex  justify-content-center text-center">
                        <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='GI'}" (click)="selectTab('GI')"
                                href="javascript:;">General</a>
                        </li>
                        <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='WalletTrading'}" (click)="selectTab('WalletTrading')"
                                href="javascript:;">Wallet Transactions</a>
                        </li>
                        <!-- <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='WithdrawalTransaction'}" (click)="selectTab('WithdrawalTransaction')"
                                href="javascript:;">Withdrawal Transactions</a>
                        </li> -->
                        <!-- <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='tokenTransaction'}"
                                (click)="selectTab('tokenTransaction')" href="javascript:;">Claim Token Management</a>
                        </li> -->
                        <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='KYC'}" (click)="userDetail?.kyc && selectTab('KYC')"
                                href="javascript:;">KYC Details</a>
                        </li>

                    </ul>
                </div>
            </div>
        </div>


        <!-- tab General -->
        <div class="tab-pane active show" *ngIf="this.currTab == 'GI'">
            <div class="order-view mt30">
                <div class="main-block-innner mb40 mt40 forBorder">
                    <div class="add-store-block input-style">
                        <div class="user-profile">
                            <div class="image-box">
                                <img [src]="userDetail?.imageUrl || 'assets/img/profile-img.jpg'" class="profile-pic"
                                    alt="profile image">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-5">
                                <label class="labelName">First Name </label>
                            </div>
                            <div class="col-2 text-left white">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p>{{userDetail?.firstName || '--'}}</p>
                            </div>

                        </div>
                        <div class="form-group row">
                            <div class="col-5">
                                <label class="labelName">Last Name </label>
                            </div>
                            <div class="col-2 text-left white">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p>{{userDetail?.lastName || '--'}}</p>
                            </div>


                        </div>
                        <div class="form-group row">
                            <div class="col-5">
                                <label class="labelName">Email </label>
                            </div>
                            <div class="col-2 text-left white">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p>{{userDetail?.email || '--'}}

                                </p>
                            </div>


                        </div>




                        <div class="form-group row">
                            <div class="col-5">
                                <label class="labelName">Mobile No.
                                </label>
                            </div>
                            <div class="col-2 text-left white">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p>{{userDetail?.phoneNo || '--'}}

                                </p>
                            </div>

                        </div>
                        <div class="form-group row">
                            <div class="col-5">
                                <label class="labelName">Country</label>
                            </div>
                            <div class="col-2 text-left white">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p>{{userDetail?.country || '--'}}
                                </p>
                            </div>

                        </div>
                        <div class="form-group row">
                            <div class="col-5">
                                <label class="labelName">Postal Code</label>
                            </div>
                            <div class="col-2 text-left white">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p>{{(userDetail?.zipCode) || '--'}}
                                </p>
                            </div>

                        </div>

                        <div class="form-group row">
                            <div class="col-5">
                                <label class="labelName">State

                                </label>
                            </div>
                            <div class="col-2 text-left white">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p>{{(userDetail?.state) || '--'}}</p>

                            </div>

                        </div>

                        <div class="form-group row">
                            <div class="col-5">
                                <label class="labelName">Address

                                </label>
                            </div>
                            <div class="col-2 text-left white">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p>{{(userDetail?.address) || '--'}}

                                </p>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-5">
                                <label class="labelName">Date of Birth
                                </label>
                            </div>
                            <div class="col-2 text-left white">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p>{{ (getDOB(userDetail?.dob) | date : 'dd-MMM-yyyy') || '--'}}
                                </p>
                            </div>

                        </div>
                        <div class="form-group row">
                            <div class="col-5">
                                <label class="labelName">IBI Name
                                </label>
                            </div>
                            <div class="col-2 text-left white">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p>{{userDetail?.ibiName || '--'}}



                                </p>
                            </div>

                        </div>
                        <div class="form-group row">
                            <div class="col-5">
                                <label class="labelName">IBI ID
                                </label>
                            </div>
                            <div class="col-2 text-left white">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p>{{userDetail?.ibiId || '--'}}
                                </p>
                            </div>

                        </div>
                        <div class="form-group row">
                            <div class="col-5">
                                <label class="labelName">2 FA
                                </label>
                            </div>
                            <div class="col-2 text-left white">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p>{{userDetail?.twoFaType || '--'}}
                                </p>
                            </div>

                        </div>
                        <div class="form-group row">
                            <div class="col-5">
                                <label class="labelName">Withdraw Address
                                </label>
                            </div>
                            <div class="col-2 text-left white">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p>{{userDetail?.withdrawAddress || '--'}}
                                </p>
                            </div>

                        </div>
                       
                    </div>
                    <div class="text-left mt40 text-center" style="padding-bottom: 20px;">
                        <a routerLink="/user-management" class="btn btn-large  max-WT-200 font-100 btn-green">Back</a>
                        <!-- <button class="btn btn-large  max-WT-200 font-100 btn-green ml-5" (click)="openTransferModal()"
                                >Transfer Rewards</button> -->
                    </div>
                </div>
            </div>
        </div>




        <!-- tab login session end -->

        <div class="tab-pane active show" *ngIf="this.currTab == 'WalletTrading'">
            <div class="order-view mt30  mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <app-wallet-management [userId]="id"></app-wallet-management>
                </div>
            </div>
        </div>
        <div class="tab-pane active show" *ngIf="this.currTab == 'WithdrawalTransaction'">
            <div class="order-view mt30  mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <app-user-withdrawl-transaction [userIdNew]="userDetail?.email"></app-user-withdrawl-transaction>
                </div>
            </div>
        </div>

        <!-- <div class="tab-pane active show" *ngIf="this.currTab == 'tokenTransaction'">
            <div class="order-view mt30  mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <app-token-trnsaction-detail [userId]="id"></app-token-trnsaction-detail>
                </div>
            </div>
        </div> -->

        <div class="tab-pane active show" *ngIf="this.currTab == 'KYC'" >
            <div class="order-view mt30  mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <app-user-kycdetail [userId]="id"></app-user-kycdetail>
                </div>
            </div>
        </div>

    </div>
</main>
<footer>
    <div class="container-fluid">
        <p class="copyright" style="margin-right: -3%">FIERES ICO Crypto © 2023 All Rights Reserved.
            <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>

<div class="modal fade global-modal reset-modal" id="transferAmount">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3  style="text-align: center; ">Transfer Rewards Amount </h3>
                        <hr>
                        <!-- <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button> -->
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                               <form [formGroup]="loylity">
                                <div class="form-group row mt-2 mb-5">
                                    <div class="col-md-12 col-sm-12">
                                      <input type="text" class="form-control" formControlName="loyalityAmount" placeholder="Enter Transfer Amount"
                                        (keypress)="service.preventSpace($event)" />
                                    </div>
                    
                                  </div>
                               </form>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2"
                                        (click)="transferLoyltyMoney()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>