<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">Vesting Management</h1>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                        <!-- <div class="user-main-head sec_head_new sec_head_new_after">
                            <form [formGroup]="userForm">
                                <div class="row form-group">
                                    <div class="col-md-3 ">
                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center"
                                                    style="margin-left: 5px!important">Status </span>
                                                <select name="" id="" class="form-control form1"
                                                    formControlName="status">
                                                    <option value="">Select status</option>
                                                    <option value="RESOLVED">RESOLVED</option>
                                                    <option value="INPROGRESS">INPROGRESS</option>
                                                    <option value="CANCELLED">CANCELLED</option>
                                                    <option value="CLOSED">CLOSED</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-3 col-md-2 col-sm-6 ">
                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center"
                                                    style="margin-left: 5px!important">From Date </span>

                                                <input type="date" class="form-control form" formControlName="startdate" onkeydown="return false"
                                                    [max]="today">
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-3 col-md-2 col-sm-6">

                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center">To Date</span>
                                                <input type="date" class="form-control form" onkeydown="return false"
                                                    [readonly]="!this.userForm.controls['startdate'].value"
                                                    [min]="userForm.controls['startdate'].value"
                                                    formControlName="enddate">
                                            </div>
                                        </div>

                                    </div>
                                   
                                    <div class="col-lg-3 col-md-4 col-sm-12">

                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center op-0"> Search</span>
                                                <input type="text" class="form-control" placeholder="Search by email"
                                                    formControlName="searchText">
                                            </div>
                                        </div>

                                    </div>


                                </div>
                                <div class="col-md-12" style="justify-content: end; display: flex;">
                                    <button type="submit" class="btn  btn-theme" (click)="reset()">Clear
                                    </button>

                                    <button type="submit" class="btn  btn-theme cus-mr ml-2"
                                        (click)="pageNumber = 1;filterTicketList()">Apply</button>

                                    <button type="submit" class="btn  btn-theme " *ngIf="service.isShow(permissionArr,'QUERY_MANAGEMENT','UPDATE')" (click)="exportAsXLSX()">EXPORT AS
                                        EXCEL
                                    </button>

                                </div>


                            </form>
                        </div> -->

                        <div class="row  d-flex justify-content-end w_100">

                            <!-- <button type="submit" class="btn  btn-theme mr-3" (click)="userTypePlan('Plan')">Change User
                                Plan
                            </button> -->

                            <button type="submit" class="btn  btn-theme " routerLink="/vesting-plan-add">Create New Plan
                            </button>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="user list">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th scope="col">Id</th>
                                        <th scope="col">Plan Name</th>
                                        <th scope="col">Token Price</th>
                                        <th scope="col">Token Sell Limit</th>
                                        <th scope="col">Terms (Days)</th>
                                        <th scope="col">Release Time Period (Days)</th>
                                        <th scope="col">Commission</th>
                                        <th scope="col">Plan Start Date</th>
                                        <th scope="col">Plan End Date</th>
                                        <!-- <th scope="col" *ngIf="service.isShow(permissionArr,'QUERY_MANAGEMENT','UPDATE')" class="action_td_btn3">Action</th> -->
                                        <th scope="col" class="action_td_btn3">Action</th>
                                    </tr>
                                </thead>
                                <!-- <tbody *ngIf="ticketList?.length">
                                    <tr class="new-hgt-tab" *ngFor="let item of ticketList| paginate: { 
                                        itemsPerPage: itemsPerPage,
                                        currentPage: pageNumber,
                                        totalItems: totalRecords}; let i=index"> -->
                                <tbody>
                                    <tr class="new-hgt-tab" *ngFor="let item of vestingPlanList">
                                        <td>{{item?.id}}</td>
                                        <td>{{item?.planName}}</td>
                                        <td>{{item?.tokenPrice}} USD</td>
                                        <td>{{item?.sellLimit}} Fiero</td>
                                        <td>{{item?.terms}} Days</td>
                                        <td>{{item?.releaseTerm}} Days</td>
                                        <td>{{item?.comission}}</td>
                                        <td>{{item?.startDate | date : 'medium'}}</td>
                                        <td>{{item?.endDate | date : 'medium'}}</td>
                                        <td class="action_td_btn3 pl-0" style="text-overflow: unset;
                                        max-width: 100% !important;
                                        width: auto;">
                                             <a (click)="openModal(item?.id,'whitelist')">Add Whitelist</a>
                                             <a (click)="openModal(item?.id,'blacklist')">Add Blacklist</a>
                                            <a  (click)="viewPlan(item?.id)"><i class="fa fa-eye" aria-hidden="true"></i></a>
                                            <a  (click)="editPlan(item?.id)"><i class="fa fa-edit" aria-hidden="true"></i></a>
                                            <a  (click)="removePlanModal(item?.id)"><i class="fa fa-trash" aria-hidden="true"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                                <!-- <tbody *ngIf="!ticketList?.length">
                                    <tr>
                                        <td colspan="10" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <h5>No record found</h5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody> -->
                            </table>
                        </div>

                        <!-- <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end">
                            <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
                        </div> -->
                        <!-- <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                            *ngIf="totalRecords > 10">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div> -->


                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<footer>
    <div class="container-fluid">
        <p class="copyright">FIERES ICO Crypto © 2023 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>

<div class="modal fade global-modal reset-modal" id="addPlanModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password" [formGroup]="modalForm">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 20px;">Below entered users will be whitelisted from this plan.</p>

                                <div class="row" style="margin-bottom: 10px;">
                                    <div class="col-md-4 ">
                                        <p style="font-size: 16px;">Email Id:</p>
                                    </div>
                                    <div class="col-md-8">
                                        <textarea formControlName="address" class="form-control" placeholder="Enter Email ID (abc@gmail.com,ab@gmail.com,test@gmail.com...)"
                                            id="exampleFormControlTextarea1" rows="3" style="height: 115px;"></textarea>
                                    </div>
                                </div>
                                <div style="margin-left: 27%;">
                                    <button type="submit" class="btn btn-info mr-2 " style="width: 25%;" (click)="addWhiteList()"
                                        >Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="modal fade global-modal reset-modal" id="addBlacklist">
    <div class="modal-dialog max-WT-500">
        <form class="change_password" [formGroup]="modalFormBlacklist">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 20px;">Below entered users will be blacklisted from this plan. </p>

                                <div class="row" style="margin-bottom: 10px;">
                                    <div class="col-md-4 ">
                                        <p style="font-size: 16px;">Email Id:</p>
                                    </div>
                                    <div class="col-md-8">
                                        <textarea formControlName="emailAddress" class="form-control" placeholder="Enter Email ID (abc@gmail.com,ab@gmail.com,test@gmail.com...)"
                                            id="exampleFormControlTextarea1" rows="3" style="height: 115px;"></textarea>
                                    </div>
                                </div>
                                <div style="margin-left: 27%;">
                                    <button type="submit" class="btn btn-info mr-2 " style="width: 25%;" (click)="addBlackListFuc()"
                                        >Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- active Start -->
<div class="modal fade global-modal reset-modal" id="removePlan">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Remove Plan </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to remove this plan?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2" (click)="deletePlan()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>