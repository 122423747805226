<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#fff">Transactions

        </h1>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="row nlp">
                        <div class="col-5">
                            <label for="">Completed Claim Amount</label>
                        </div>
                        <div class="col-1">:</div>
                        <div class="col-5 d-flex">
                           <p class="amt">{{userDetailData?.completedClaimAmount}}</p>
                        </div>
                    
                        <div class="col-5">
                            <label for="">Pending Claim Amount</label>
                        </div>
                        <div class="col-1">:</div>
                        <div class="col-5 d-flex">
                            <p class="amt">{{userDetailData?.pendingClaimAmount}}</p>
                        </div>
                    </div>
                    <div class="tab-pane1">
                        <div class="user-main-head sec_head_new sec_head_new_after">
                            <form [formGroup]="userForm">
                                <div class="row form-group">
                                    <div class="col-lg-3 col-md-4 col-sm-6 ">
                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center"
                                                    style="margin-left: 5px!important">From Date </span>

                                                <input type="date" class="form-control form" onkeydown="return false"
                                                    formControlName="startdate" [max]="today">
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">

                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center">To Date</span>
                                                <input type="date" class="form-control form" onkeydown="return false"
                                                    [readonly]="!this.userForm.controls['startdate'].value"
                                                    [min]="userForm.controls['startdate'].value"
                                                    formControlName="enddate">
                                            </div>
                                        </div>

                                    </div>
                                    <br>

                                    <div class="col-lg-3 col-md-4 col-sm-6" style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group w-20">

                                                <input type="datetime" class="form-control w-75"
                                                    placeholder="Enter Email" formControlName="searchText">

                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-4 col-sm-6" style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group">
                                                <select formControlName="userStatus" class="form-control form1">
                                                    <option value="">Select Status</option>
                                                    <option value="PENDING">PENDING</option>
                                                    <option value="CONFIRM">CONFIRM</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6" style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group">
                                                <div class="text-left">
                                                    <input type="text" class="form-control" formControlName="ramdomId"
                                                        placeholder="Random Id">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6 " style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group">
                                                <div class="text-left">
                                                    <input type="text" class="form-control" formControlName="firstName"
                                                        placeholder="First Name">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6 " style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group">
                                                <div class="text-left">
                                                    <input type="text" class="form-control" formControlName="lastName"
                                                        placeholder="Last Name">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6 " style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group">
                                                <div class="text-left">
                                                    <input type="text" class="form-control" formControlName="ibiid"
                                                        placeholder="IBI Id">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6" style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group">
                                                <select formControlName="vestingPlan" class="form-control form1">
                                                    <option value="">Select Vesting Plan</option>
                                                    <option *ngFor="let item of vestingPlanList" [value]="item?.id">
                                                        {{item?.planName}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6" style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group">
                                                <input type="text" class="form-control" formControlName="address"
                                                placeholder="Address">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-7" style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group">
                                                <div class="text-left" style="margin-left: 1%">
                                                    <button type="button" class="btn  btn-theme cus-mr"
                                                        (click)="pageNumber = 1;newValue('PER_DAY')">CLAIM
                                                        HISTORY</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6" style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group">
                                                <div class="text-left" style="margin-left: 1%">
                                                    <button type="button" class="btn  btn-theme cus-mr"
                                                        (click)="pageNumber = 1;newValue('REWARDED')">REWARDED</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row col-md-12 justify-content-end">

                                        <div class="d-flex ">

                                            <div class=" " style="margin-top:26px;"
                                                *ngIf="service.isShow(permissionArr,'TRANSACTION_MANAGEMENT','UPDATE')">
                                                <div class="serch-boxuser">
                                                    <div class="input-group filter_search_group">
                                                        <div class="text-left" style="margin-left: 1%">
                                                            <button type="button" class="btn  btn-theme cus-mr"
                                                                (click)="exportAsXLSX()">EXPORT AS EXCEL</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class=" " style="margin-top:26px;">
                                                <div class="serch-boxuser">
                                                    <div class="input-group filter_search_group">
                                                        <div class="text-left" style="margin-left: 1%">
                                                            <button type="submit" class="btn  btn-theme cus-mr"
                                                                (click)="pageNumber = 1;getlist()">Apply</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class=" " style="margin-top:26px;">
                                                <div class="serch-boxuser">
                                                    <div class="input-group filter_search_group">
                                                        <div class="head_flt_select input-label"
                                                            style="margin-left: 1%">
                                                            <button type="button" class="btn  btn-theme"
                                                                (click)="reset()">Clear


                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                    <!-- <div class="col-md-12 user-lst p-0" style="margin-top: 18px;display: flex;justify-content: end;padding-right: 21px !important;">
                                        <div class="two-btnline">
                                            <div class="text-left" style="margin-left: 1%">
                                                <button type="submit" class="btn  btn-theme cus-mr"
                                                    (click)="search()">Search</button>
                                            </div>
                                            <div class="head_flt_select">
                                                <div class="head_flt_select input-label" style="margin-left: 1%">
                                                    <button type="submit" class="btn  btn-theme" (click)="reset()">Reset
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="head_flt_select">
                                                <div class="head_flt_select input-label" style="margin-left: 1%">
                                                    <button type="submit" class="btn  btn-theme " (click)="exportAsXLSX()">EXPORT AS EXCEL
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>

                            </form>
                        </div>
                        <!-- <div class="row  d-flex justify-content-end w_100">

                            <button type="submit" class="btn  btn-theme " (click)="exportAsXLSX()">EXPORT AS EXCEL
                            </button>
                        </div> -->


                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="user list">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th scope="col">S.No.</th>
                                        <th scope="col">Type
                                        </th>
                                        <!-- <th scope="col">Transaction Id
                                        </th> -->
                                        <th scope="col">First Name</th>
                                        <th scope="col">Last Name</th>
                                        <th scope="col">Address</th>
                                        <th scope="col">Plan Name</th>

                                        <th scope="col" style="min-width: 200px;">Date and time</th>
                                        <th scope="col" style="min-width: 200px;">Status updated at &nbsp;<i
                                                class="fa fa-sort" (click)="sortAgent('DATE')" aria-hidden="true"
                                                style="cursor: pointer;"></i></th>
                                        <th scope="col" style="min-width: 251px;">Email Id&nbsp;<i
                                            class="fa fa-sort" (click)="sortAgent('EMAIL')" aria-hidden="true"
                                            style="cursor: pointer;"></i></th>
                                        <th scope="col">Fieres allocated</th>
                                        <th scope="col">GBP Amount</th>
                                        <th scope="col">Currency</th>
                                        <th scope="col">Random Id</th>
                                        <th scope="col">IBI Name</th>
                                        <th scope="col">IBI Id</th>
                                        <th scope="col">Volume</th>
                                        <th scope="col">Fiero Price</th>
                                        <th scope="col">Claim Request</th>
                                        <th scope="col">Country&nbsp;<i
                                            class="fa fa-sort" (click)="sortAgent('COUNTRY')" aria-hidden="true"
                                            style="cursor: pointer;"></i></th>
                                        <th scope="col"> Payment Status </th>

                                        <th scope="col" class="action_td_btn3">Action</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="listing?.length">
                                    <tr *ngFor="let item of listing | paginate: { 
                                    itemsPerPage: itemsPerPage,
                                    currentPage: pageNumber,
                                    totalItems: totalRecords };let i = index">
                                        <td>{{itemsPerPage*(this.pageNumber-1)+i+1}}</td>
                                        <td class="name">{{item?.txnType}}</td>
                                        <!-- <td>{{item?.txnId || 'N/A'}}</td> -->

                                        <td>{{item?.firstName || 'N/A'}}</td>
                                        <td>{{item?.lastName || 'N/A'}}</td>
                                        <td>{{item?.address || 'N/A'}}</td>
                                        <td>{{item?.planData?.planName || 'N/A'}}</td>
                                        <td>{{item?.txnTime | date: 'medium'}}</td>
                                        <td>{{item?.updateDate == null ? 'N/A': item?.updateDate == 'NA' ? 'N/A'
                                            :(item?.updateDate | date: 'medium') }}</td>
                                        <td>{{item?.userEmail || 'N/A'}}</td>

                                        <td>{{item?.amount || 'N/A'}}</td>
                                        <td>{{item?.gbpAmount || 'N/A'}}</td>
                                        <td>{{item?.usdtAmount}}&nbsp;{{item?.baseCurrency || 'N/A'}}</td>

                                        <td>{{item?.randomId || 'N/A'}}</td>
                                        <td>{{item?.ibiName || 'N/A'}}</td>
                                        <td>{{item?.ibiId || 'N/A'}}</td>
                                        <td>{{item?.voulme || 'N/A'}}</td>
                                        <td>{{getLivePrice(item?.lastFieroPrice) || 'N/A'}}</td>
                                        <td>{{item?.claimRequest || 'N/A'}}</td>
                                        <td>{{item?.country && getCountryCode(item?.country) || 'N/A'}}</td>
                                        <td>{{item?.status || 'N/A'}}</td>

                                        <td class="action_td_btn3">
                                            <a><i class="fa fa-eye" [routerLink]="['/transactionView']"
                                                    [queryParams]="{'id' : item.txnId,'txnType': item.txnType}"
                                                    *ngIf="service.isShow(permissionArr,'TRANSACTION_MANAGEMENT','CREATE')"
                                                    aria-hidden="true"></i></a>
                                            <ng-container
                                                *ngIf="service.isShow(permissionArr,'TRANSACTION_MANAGEMENT','UPDATE')">
                                                <a *ngIf="item?.status == 'PENDING' && item?.txnType == 'CLAIM' "><i
                                                        class="fa fa-check" style="color: green;"
                                                        (click)="approved(item?.txnId)" aria-hidden="true"></i></a>
                                            </ng-container>

                                        </td>
                                    </tr>



                                    <!-- <tr>
                                        <td>Deposit
                                        </td>
                                        <td>1

                                        </td>
                                        <td>Aadya
                                        </td>
                                        <td>16 march,2023 5:10 pm

                                        </td>
                                        <td>aadya@mailinator.com </td>
                                        <td>150 USD

                                        </td>
                                        <td>BNB  </td>
                                        <td>Successfull  </td>
                                  
                                        <td class="action_td_btn3">
                                            <a ><i class="fa fa-eye" [routerLink]="['/transactionView']"
                                                    aria-hidden="true"></i></a>
                                            <a ><i class="fa fa-trash"
                                                    aria-hidden="true"></i></a>
                                            

                                        </td>
                                    </tr> -->

                                </tbody>
                                <tbody *ngIf="!listing?.length">
                                    <tr>
                                        <td colspan="20" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <h5>No record found</h5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                            *ngIf="totalRecords > 10">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<footer>
    <div class="container-fluid">
        <p class="copyright">FIERES ICO Crypto © 2023 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>


<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Delete User </h3>

                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to delete this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2" (click)="deleteUser()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->

<!-- block_modal Start -->
<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 *ngIf="userStatus=='ACTIVE'" style="text-align: center; ">Unblock </h3>
                        <h3 *ngIf="userStatus=='BLOCK'" style="text-align: center; color: #fff;">Block </h3>

                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p *ngIf="userStatus=='ACTIVE'" style="font-size: 25px;">Are you sure you want to
                                    unblock?</p>
                                <p *ngIf="userStatus=='BLOCK'" style="font-size: 25px;  color: #fff;">Are you sure you
                                    want to block?
                                </p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2 " style="width: 25%;"
                                        (click)="blockUser()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- active Start -->
<div class="modal fade global-modal reset-modal" id="active">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Active User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to active this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- appraved_modal Start -->
<div class="modal fade global-modal reset-modal" id="approveModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Approve</h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <!-- <p style="font-size: 25px;">Are you sure you want to approve kyc with data entered?</p> -->
                                <p style="font-size: 25px;">Do you want to release this fund?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2 " style="width: 25%;"
                                        (click)="approveKyc()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>