<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title" style="color:#26272d">STATIC CONTENT MANAGEMENT</h1>
        </div>

        <!-- tab start -->
        <!-- <div class="custom_tabs common-tabs">
            <div class="row mb20 justify-content-center">
                <div class="col-sm-11" style="padding-right: 0px;padding-left: 0px;">
                    <ul class="nav nav-tabs d-flex  justify-content-center text-center">
                        <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='English'}" (click)="selectTab('English')"
                                href="javascript:;">English</a>
                        </li>
                        <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='German'}" (click)="selectTab('German')"
                                href="javascript:;">German</a>
                        </li>
                        <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='Spanish'}" (click)="selectTab('Spanish')"
                                href="javascript:;">Spanish</a>
                        </li>

                    </ul>
                </div>
            </div>
        </div> -->
        <!-- tab end -->



        <!-- tab for english static start -->
        <div class="tab-pane active show">
            <div class="content-section">
                <!-- <div class="add-new-header-btn-align text-right mb-3">
                    <button class="btn btn-success btn-theme" routerLink="/add-static">Add Static Content</button>
                </div> -->
                <div class="outer-box">
                    <div class="global-table no-radius p0">
                        <div class="tab-content1">
                            <div class="tab-pane1">
                                <div class="table-responsive">
                                    <table class="table table-bordered" aria-describedby="static content list">
                                        <thead>
                                            <tr class="no_wrap_th">
                                                <th scope="col">ID</th>
                                                <th scope="col">Page Name </th>
                                                <th scope="col" class="action_td_btn3">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let items of staticData">
                                                <td>{{items.staticContentId}}</td>
                                                <td>{{items.pageKey}}</td>
                                                <td (click)="englishAbout(items.pageKey)" ><i class="fa fa-eye"
                                                        aria-hidden="true" style="cursor: pointer;"></i></td>
                                            </tr>
                                            <!-- <tr>
                                                <td>{{staticData.staticContentId}}</td>
                                                <td>{{getName(staticData.pageKey)}}</td>
                                                <td (click)="englishAbout(staticData.pageKey)"><i class="fa fa-eye"
                                                        aria-hidden="true" style="cursor: pointer;"></i></td>
                                            </tr>
                                            <tr>
                                                <td>{{staticDataS.staticContentId}}</td>
                                                <td>{{getName(staticDataS.pageKey)}}</td>
                                                <td (click)="englishAbout(staticDataS.pageKey)"><i class="fa fa-eye"
                                                        aria-hidden="true" style="cursor: pointer;"></i></td>
                                            </tr>
                                            <tr>
                                                <td>{{staticDataSs.staticContentId}}</td>
                                                <td>{{getName(staticDataSs.pageKey)}}</td>
                                                <td (click)="englishAbout(staticDataSs.pageKey)"><i class="fa fa-eye"
                                                        aria-hidden="true" style="cursor: pointer;"></i></td>
                                            </tr> -->
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
<footer>
    <div class="container-fluid">
        <p class="copyright">FIERES ICO Crypto © 2023 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>