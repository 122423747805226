<!--  <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="shadow p-3 mb-5 bg-white rounded round">
                    <div class="tab-pane1">
                      
                            <div class="col-md-3">
                                <div class="head_flt_select">
                                    <div>
                                        <span class="labelcss "> Withdrawal Amount:</span>
                                        <input type=""  (keypress)="numberOnly($event)" class="form-control" maxlength="3" [(ngModel)]="withdrawlAmount"
                                            class="formcss" pattern="^[0-9]" required>
                                        <h3 style="margin-left: 295%;margin-top: -17%;">%</h3>
                                       
                                    </div>
                                </div>
                            </div>
                           
                            <button type="submit" class="btn  btn-theme cus-mr"style="width: 33%;margin-left: 32%;margin-top: 5%;" (click)="submitAmount()">Submit</button>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div> -->

<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">VIEW LIMIT</h1>
    </div>
    <div class="outer-box">

        <div class="global-table no-radius p0">
            <div class="row view_block">
                <div class="col-4 col-sm-12">
                    <h5>Withdrawal Amount</h5>
                </div>
                <div class="col-8 col-sm-12">
                    <input type="number"  class="form-control" maxlength="7"
                        [(ngModel)]="withdrawlAmount"  pattern="^[0-9]" required>
                </div>
                <div class="col-12 text-center mt-3">
                    <button type="submit" class="btn  btn-theme cus-mr" 
                    (click)="submitAmount()">save</button>
                </div>
            </div>
            <!-- <div class="shadow p-3 mb-5 bg-white rounded"
                style="width: 60%;height: 200px;margin-top: 10%;margin-left: 20%;">
                <div style="display: flex;margin-top: 10%;margin-left: 10%;">
                    <h5>Withdrawal Amount:</h5>

                    <input type="" style="margin-left: 3%;
                            width: 41%;
                            margin-top: -1%;" (keypress)="numberOnly($event)" class="form-control" maxlength="3"
                        [(ngModel)]="withdrawlAmount" class="formcss" pattern="^[0-9]" required>
                </div>
                <div style="margin-top: 4%;margin-top: 4%;
                        margin-left: 61%">
                    <button type="submit" class="btn  btn-theme cus-mr" style="width: 35%;"
                        (click)="submitAmount()">save</button>

                </div>
            </div> -->
        </div>

    </div>

</main>

<footer style="    margin-top: 38%;">
    <div class="container-fluid">
        <p class="copyright">FIERES ICO Crypto © 2023 All Rights Reserved.<strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>