<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">Sub Admin Activity</h1>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                        <div class="user-main-head sec_head_new sec_head_new_after">
                            <form [formGroup]="userForm">
                                <div class="row form-group">
                                    <!-- <div class="col-md-3 ">
                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center"
                                                    style="margin-left: 5px!important">Management </span>
                                                <select name="" id="" class="form-control form1"
                                                    formControlName="status">
                                                    <option value="">Select Management</option>
                                                    <option [value]="item.keyName" *ngFor="let item of permArray">{{item.keyValue}}</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div> -->
                                    <div class="col-lg-3 col-md-2 col-sm-6 ">
                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center"
                                                    style="margin-left: 5px!important">From Date </span>

                                                <input type="date" class="form-control form" formControlName="startdate" onkeydown="return false"
                                                    [max]="today">
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-3 col-md-2 col-sm-6">

                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center">To Date</span>
                                                <input type="date" class="form-control form" onkeydown="return false"
                                                    [readonly]="!this.userForm.controls['startdate'].value"
                                                    [min]="userForm.controls['startdate'].value"
                                                    formControlName="enddate">
                                            </div>
                                        </div>

                                    </div>
                                    <!-- <br> -->

                                    <!-- <div class="col-lg-2 col-md-2 col-sm-12  colum" style="margin-top:1.5%;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group w-20">
                                                <input type="text" class="form-control w-75"
                                                    placeholder="Search by email" formControlName="searchText">
                                                <div class="input-group-append">
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-lg-4 col-md-4 col-sm-12">

                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center op-0"> Search</span>
                                                <input type="text" class="form-control" placeholder="Search by email"
                                                    formControlName="searchText">
                                            </div>
                                        </div>

                                    </div>


                                </div>
                                <div class="col-md-12" style="justify-content: end; display: flex;">
                                    <button type="submit" class="btn  btn-theme" (click)="reset()">Clear
                                    </button>

                                    <button type="submit" class="btn  btn-theme cus-mr ml-2"
                                        (click)="pageNumber = 1;getTicketList()">Apply</button>

                                    <!-- <button type="submit" class="btn  btn-theme " *ngIf="service.isShow(permissionArr,'QUERY_MANAGEMENT','UPDATE')" (click)="exportAsXLSX()">EXPORT AS
                                        EXCEL
                                    </button> -->
                                    <button type="submit" class="btn  btn-theme " (click)="exportAsXLSX()">EXPORT AS EXCEL
                                    </button>
                                </div>


                            </form>
                        </div>
                        <!-- <div class="row  d-flex justify-content-end w_100">

                            <button type="submit" class="btn  btn-theme " (click)="exportAsXLSX()">EXPORT AS EXCEL
                            </button>
                        </div> -->


                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="user list">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th scope="col">S.No</th>
                                  
                                        <th scope="col">Sub Admin Email</th>
                                        <th scope="col">Management Name</th>
                                        <th scope="col">Activity</th>
                                        <th scope="col">Message</th>
                                        <th scope="col">Date & Time</th>
                                     
                                
                                    </tr>
                                </thead>
                                <tbody *ngIf="ticketList?.length">
                                    <tr class="new-hgt-tab" *ngFor="let item of ticketList| paginate: { 
                                        itemsPerPage: itemsPerPage,
                                        currentPage: page,
                                        totalItems: totalRecords}; let i=index">
                                        <td>{{itemsPerPage*(this.page-1)+i+1}}</td>
                                        <td >{{item.activityBy || 'N/A'}}</td>
                                        <td style="white-space: unset;overflow: unset;text-overflow: unset;width: auto;max-width: 100%;word-break: keep-all;">{{changeMessage(item.management) || 'N/A'}}</td>
                                        <td style="white-space: unset;overflow: unset;text-overflow: unset;width: auto;max-width: 100%;word-break: keep-all;">{{changeMessage(item.activityOf) || 'N/A'}}</td>
                                        <td>{{item.email || 'N/A'}}</td>
                                        <td>{{item.createdAt | date: 'medium'}}</td>
                                    

                                       
                                    </tr>

                                </tbody>
                                <tbody *ngIf="!ticketList?.length">
                                    <tr>
                                        <td colspan="10" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <h5>No record found</h5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end">
                            <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
                        </div> -->
                        <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                            *ngIf="totalRecords > 10">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<footer>
    <div class="container-fluid">
        <p class="copyright">FIERES ICO Crypto © 2023 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>


<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Delete User </h3>

                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to delete this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->

<!-- block_modal Start -->
<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Unblock </h3>
                        <h3 style="text-align: center; color: #fff;">Block </h3>

                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to unblock?</p>
                                <p style="font-size: 25px;  color: #fff;">Are you sure you want to block?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2 " style="width: 25%;">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- active Start -->
<div class="modal fade global-modal reset-modal" id="active">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Active User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to active this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>