<div class="wrapper">

    <!-- Aside End -->
    <main class="middle-content">
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title" style="color:#fff">Edit Sub-Admin Details
            </h1>

        </div>
        <!-- Page Title End -->
        <div class="content-section">
            <div class="order-view mt30">
                <div class="main-block-innner mb40 mt40">
                    <div class=" input-style">
                        <form [formGroup]="addFaqForm">
                            <div>
                                <div class="user-profile">
                                    <div class="image-box">
                                        <img src="assets/img/editprofile.png" class="profile-pic" alt="profile image">
                                        <label class="upload-label" style="cursor: pointer">
                                            <input type="file" class="hidden" accept=".png, .jpg, .jpeg"
                                                (change)="uploadImg($event)" />
                                            <i class="fas fa-camera" aria-hidden="true"></i>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row ">


                                <div class="col-md-6 col-sm-12">
                                    <label class="col-md-4" class="labelBox">Name: </label>
                                    <input type="text" class="form-control " formControlName="title"
                                        (keypress)="service.preventSpace($event)" />

                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('title').hasError('required') && (addFaqForm.get('title').dirty || addFaqForm.get('title').touched )"
                                            class="error" padding>*Question is required</p>
                                    </span>
                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('title').hasError('pattern') && (addFaqForm.get('title').dirty || addFaqForm.get('title').touched )"
                                            class="error" padding>*Please enter valid question</p>
                                    </span>
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <label class="col-md-4" class="labelBox"> Email ID:</label>
                                    <input type="text" class="form-control " formControlName="title"
                                        (keypress)="service.preventSpace($event)" />

                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('title').hasError('required') && (addFaqForm.get('title').dirty || addFaqForm.get('title').touched )"
                                            class="error" padding>*Question is required</p>
                                    </span>
                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('title').hasError('pattern') && (addFaqForm.get('title').dirty || addFaqForm.get('title').touched )"
                                            class="error" padding>*Please enter valid question</p>
                                    </span>
                                </div>
                                <div class="col-md-6 col-sm-12" style="margin-top: 20px;">
                                    <label class="col-md-4" class="labelBox"> Phone Number:</label>
                                    <input type="text" class="form-control " formControlName="title"
                                        (keypress)="service.preventSpace($event)" />

                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('title').hasError('required') && (addFaqForm.get('title').dirty || addFaqForm.get('title').touched )"
                                            class="error" padding>*Question is required</p>
                                    </span>
                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('title').hasError('pattern') && (addFaqForm.get('title').dirty || addFaqForm.get('title').touched )"
                                            class="error" padding>*Please enter valid question</p>
                                    </span>
                                </div>


                            </div>

                            <div class="text-center mt40">
                                <button class="btn btn-large  max-WT-200 font-100 btn-green mr-5"
                                    (click)="addFaqLanguage()">Add</button>
                                <a class="btn btn-large  max-WT-200 font-100 btn-grey ml8"
                                    [routerLink]="['/faq-management']" style="    height: 46px;">Cancel</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <!-- Middle Content End -->
</div>

<footer>
    <div class="container-fluid">
        <p class="copyright">FIERES ICO Crypto © 2023 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>