<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">Payment Management</h1>
    </div>
    <!-- <div class="row">
        <div class="col-md-12">
         <div class="d-flex justify-content-end">
            <h6 class="kycReqired">User Kyc Required</h6>
            <input class="top-check-data" type="checkbox" checked data-toggle="toggle" data-on="YES" data-off="NO">
         </div>
        </div>
    </div> -->
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                       
                        <div class="user-main-head sec_head_new sec_head_new_after">
                            <form [formGroup]="userForm">
                                <div class="row form-group">
                                    <!-- <div class="col-lg-3 col-md-4 col-sm-6 ">
                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center"
                                                    style="margin-left: 5px!important">From Date </span>

                                                <input type="date" class="form-control form" onkeydown="return false"
                                                    formControlName="startdate" [max]="today">
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">

                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center">To Date</span>
                                                <input type="date" class="form-control form" onkeydown="return false"
                                                    [readonly]="!this.userForm.controls['startdate'].value"
                                                    [min]="userForm.controls['startdate'].value"
                                                    formControlName="enddate">
                                            </div>
                                        </div>

                                    </div> -->
                                    <br>

                                    <div class="col-lg-3 col-md-4 col-sm-6" style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group w-20">

                                                <input type="text" class="form-control w-75"
                                                    placeholder="Enter First Name" formControlName="firstName">

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6" style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group w-20">

                                                <input type="datetime" class="form-control w-75"
                                                    placeholder="Enter Last Name" formControlName="lastName">

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6" style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group w-20">

                                                <input type="text" class="form-control w-75"
                                                    placeholder="Enter Email" formControlName="email">

                                            </div>
                                        </div>
                                    </div>

                                    <div class=" col-lg-3 col-md-4 col-sm-6" style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group">
                                                <div class="text-left" >
                                                   <input type="text" class="form-control" formControlName="ramdomId" placeholder="Random Id">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row col-md-12 justify-content-end">
                                       
                                        <div class="d-flex ">
                                            <div class=" " style="margin-top:26px;"
                                            *ngIf="service.isShow(permissionArr,'PAYMENT_MANAGEMENT','UPDATE')">
                                                <div class="serch-boxuser">
                                                    <div class="input-group filter_search_group">
                                                        <div class="text-left" style="margin-left: 1%">
                                                            <button type="submit" class="btn  btn-theme cus-mr"
                                                                (click)="exportAsXLSX()">EXPORT AS EXCEL</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class=" " style="margin-top:26px;">
                                                <div class="serch-boxuser">
                                                    <div class="input-group filter_search_group">
                                                        <div class="text-left" style="margin-left: 1%">
                                                            <button type="submit" class="btn  btn-theme cus-mr"
                                                                (click)="pageNumber = 1;kyc()">Apply</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class=" " style="margin-top:26px;">
                                                <div class="serch-boxuser">
                                                    <div class="input-group filter_search_group">
                                                        <div class="head_flt_select input-label" style="margin-left: 1%">
                                                            <button type="submit" class="btn  btn-theme"
                                                                (click)="reset()">Clear
    
    
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                            


                                    </div>
                                    <!-- <div class="col-md-12 user-lst p-0" style="margin-top: 18px;display: flex;justify-content: end;padding-right: 21px !important;">
                                        <div class="two-btnline">
                                            <div class="text-left" style="margin-left: 1%">
                                                <button type="submit" class="btn  btn-theme cus-mr"
                                                    (click)="search()">Search</button>
                                            </div>
                                            <div class="head_flt_select">
                                                <div class="head_flt_select input-label" style="margin-left: 1%">
                                                    <button type="submit" class="btn  btn-theme" (click)="reset()">Reset
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="head_flt_select">
                                                <div class="head_flt_select input-label" style="margin-left: 1%">
                                                    <button type="submit" class="btn  btn-theme " (click)="exportAsXLSX()">EXPORT AS EXCEL
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>

                            </form>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="user list">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th scope="col">S.No</th>
                                 
                                        <th scope="col">First Name</th>
                                        <th scope="col">Last Name</th>
                                        <th scope="col">Received Amount </th>
                                        <th scope="col">Assets Amount </th>
                                        <th scope="col">GBP Amount </th>
                                        <th scope="col">IBI ID </th>
                                        <th scope="col">IBI Name </th>
                                        <th scope="col">Random Id</th>
                                        <th scope="col" style="min-width: 250px;">Email</th>
                                        <!-- <th scope="col">ID </th> -->
                                        <!-- <th scope="col">Card Id</th> -->
                                        <th scope="col">Pay Address </th>
                                        <th scope="col">Pay Amount </th>
                                        <!-- <th scope="col">Payment ID</th> -->
                                        <th scope="col">Payment Status</th>
                                        <!-- <th scope="col">Price Amount</th> -->
                                        <!-- <th scope="col">Purchase ID</th> -->
                                        <th scope="col">Allocated Amount</th>
                                        <th scope="col">Created Date</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let data of paymentData | paginate:{itemsPerPage:itemPerPage, currentPage:pageNumber, totalItems:totalRecords}; let i=index">
                                        <td>{{itemPerPage*(this.pageNumber-1)+i+1}}</td>
                                        <td>{{data.firstName || 'N/A'}}</td>
                                        <td>{{data.lastName || 'N/A'}}</td>
                                        <td>{{data.amountReceived || 'N/A'}}</td>
                                        <td>{{data.assetAmont || 'N/A'}}</td>
                                        <td>{{data.gbpAmount || 'N/A'}}</td>
                                        <td>{{data.ibiId || 'N/A'}}</td>
                                        <td>{{data.ibiName || 'N/A'}}</td>
                                        <td>{{data.orderId || 'N/A'}}</td>
                                        <td>{{data.email || 'N/A'}}</td>
                                        <!-- <td>{{data.id || 'N/A'}}</td> -->
                                        <td>
                                            <div style="display: inline-block; margin-right: 5px; ">
                                                {{shortAddress(data.payAddress) || 'N/A'}}</div> <i class="fa fa-clone"
                                                (click)="copyToClipboard(data.payAddress)" aria-hidden="true"></i>
                                        </td>
                                        <td>{{data.payAmount || 'N/A'}}</td>
                                        <!-- <td>{{data.paymentId || 'N/A'}}</td> -->
                                        <td>{{(data.paymentStatus || 'N/A')}}</td>
                                        <!-- <td>{{(data.purchaseId || 'N/A')}}</td> -->
                                        <td>
                                            <div style="display: inline-block; margin-right: 5px; ">
                                                {{(shortAddress(data.toAllocateAmount) || 'N/A')}}</div><i
                                                class="fa fa-clone" (click)="copyToClipboard(data.toAllocateAmount)"
                                                aria-hidden="true"></i>
                                        </td>
                                        <td>{{(data.createDate | date:'medium')}}</td>
                                        <td class="action_td_btn3">
                                            <a (click)="navigateToView(data)"  *ngIf="service.isShow(permissionArr,'PAYMENT_MANAGEMENT','READ')"><i class="fa fa-eye"
                                                    aria-hidden="true"></i></a>
                                            <!-- routerLink="/payment-view/{{data.userId}}" -->
                                            <!-- <a (click)="approved(data?.paymentId)"
                                                *ngIf="data.paymentStatus != 'finished'"><i class="fa fa-check"
                                                    style="color: green;" aria-hidden="true"></i></a> -->

                                        </td>
                                    </tr>
                                    <tr *ngIf="paymentData?.length==0">
                                        <td colspan="12" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5 style="">No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
        
                        </div>
                        <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                            *ngIf="totalRecords > 10">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<footer>
    <div class="container-fluid">
        <p class="copyright">FIERES ICO Crypto © 2023 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>
<!-- appraved_modal Start -->
<div class="modal fade global-modal reset-modal" id="approveModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Payment Status</h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <!-- <p style="font-size: 25px;">Are you sure you want to approve kyc with data entered?</p> -->
                                <p style="font-size: 25px;">Are you sure you want to change the payment status?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2 " style="width: 25%;"
                                        (click)="approveKyc()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>