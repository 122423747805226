<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title" style="color:#26272d">CONTENT MANAGEMENT</h1>
        </div>
        <div class="content-section">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style">
                        <div class="form-group row ">
                            <label class="col-md-4">Title :</label>
                            <div class="col-md-8">
                                <textarea class="form-control common-textarea" readonly></textarea>
                            </div>
                        </div>
                        <div class="form-group row ">
                            <label class="col-md-4">Body:</label>
                            <div class="col-md-8">
                                <textarea cols="75" rows="10" class="form-control common-textarea"></textarea>
                            </div>
                        </div>
                        <div class="text-center mt40">
                            <a href="javascript:void(0)"
                                class="btn btn-large  max-WT-200 font-100 btn-green mr-2">Save</a>
                            <a href="javascript:void(0)" class="btn btn-large  max-WT-200 font-100 btn-grey ml5"
                                [routerLink]="['/static-content-management']">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <footer>
        <div class="container-fluid">
            <p class="copyright">FIERES ICO Crypto © 2023 All Rights Reserved. <strong
                    class="theme-text-color"></strong>
            </p>
        </div>
    </footer>
</div>