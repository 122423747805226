import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

declare var $

@Component({
  selector: 'app-vesting-plan-edit',
  templateUrl: './vesting-plan-edit.component.html',
  styleUrls: ['./vesting-plan-edit.component.css']
})
export class VestingPlanEditComponent implements OnInit {
  selectedOption: string = 'option1'; // Variable to store the selected option
  modalForm: FormGroup;
  modalFormBlacklist: FormGroup;
  addFaqForm: FormGroup;
  pageNumber: number = 1
  currTab: any;
  userId: any
  today: any = new Date().toISOString().slice(0, 16)
  loylity: FormGroup
  // Creating an array in JavaScript
  daysList = [
    {name:3},
    {name:30},
    {name:60},
    {name:90},
    {name:120},
    {name:180},
    {name:365},
    {name:730}
  ];
  dataListRelease = [
    {name:15},
    {name:30},
    {name:180},
    {name:365},
    {name:550},
    {name:730},
    {name:920},
    {name:1095},
    {name:1280},
    {name:1460}
]
// daysList = [{name:3},
//   {name:30},
//   {name:60},
//   {name:90},
//   {name:120},
//   {name:360},
//   {name:720}
// ];
// dataListRelease = [{name:3},
//   {name:30},
//   {name:60},
//   {name:90},
//   {name:120},
//   {name:360},
//   {name:720}
// ];
  planId: any
  planDetails: any
  isRadioEnabled = true; // Initial state is enabled
  constructor(public route: Router, public service: MainService, public active: ActivatedRoute, private datePipe: DatePipe) {
    this.active.queryParams.subscribe((res) => {
      this.planId = res.id
    })
  }

  ngOnInit(): void {
    this.formValidation();
    this.modalForm = new FormGroup({
      address: new FormControl("", Validators.required)
    });
    this.modalFormBlacklist = new FormGroup({
      emailAddress :new FormControl("", Validators.required)
    })
    this.viewPlan();
    this.getWhiteListedUser();
    this.getBlackListUser();
    this.viewFee();
   

  }

  formValidation() {
    this.addFaqForm = new FormGroup({
      'planName': new FormControl(''),
      'tokenPrice': new FormControl('', [Validators.required]),
      'tokenSellLimit': new FormControl('', [Validators.required]),
      'terms': new FormControl(''),
      'planStartdate': new FormControl(''),
      'planEndDate': new FormControl('', [Validators.required]),
      'withdrawaCommission': new FormControl(''),
      'releaseTimePeriod': new FormControl(''),
      // 'oldPlanOldUser': new FormControl(''),
      'newPlanOldUser': new FormControl(''),
      // 'oldPlanNewUser': new FormControl(''),
      'newPlanNewUser': new FormControl(''),
      'image': new FormControl(''),
      'minGbpAmount': new FormControl(''),
      'salePrice': new FormControl('', [Validators.required])
      // 'saleTranchesPrice': new FormControl('', [Validators.required]),
      // 'salePrice': new FormControl('', [Validators.required])

    })
  }

  openModalFun() {
    this.isRadioEnabled = !this.isRadioEnabled;
    // this.getFieroPrice()
    this.markTab  = this.planDetails.markType
    if(this.markTab === this.planDetails.markType){
      this.addFaqForm.patchValue({
        'tokenPrice': this.planDetails.tokenPrice.toFixed(2),
      })
      // this.finalPrice = this.planDetails.tokenPrice,
      // this.fieroPrices = this.planDetails.tokenPrice
      this.markDownPercentage = this.planDetails.markDownPercentage,
      this.markUpPercentage = this.planDetails.markUpPercentage
    }
    // else{
    //   if (this.markTab == 'UP' || this.markTab == 'DOWN') {
    //     this.addFaqForm.patchValue({
    //       tokenPrice: this.finalPrice
    //     })
    //   } else {
    //     this.addFaqForm.patchValue({
    //       tokenPrice: this.fieroPrices
    //     })
    //   }
    // }
    this.setMarkTab(this.planDetails.markType)
    $('#markUpModal').modal('show')
  }

  // Function to handle the change event when a radio button is clicked
  onOptionChange(): void {
    if (this.addFaqForm.value.salePrice === 'TRANC') {
      this.addFaqForm.patchValue({
        'tokenPrice': this.planDetails.tokenPrice.toFixed(2),
      })
      $('#markUpModal').modal('show')
    } else {
      this.addFaqForm.patchValue({
        'tokenPrice': this.planDetails.tokenPrice.toFixed(2),
      })
    }

  }
  showUserDetail() {
    this.service.showSpinner();
    var url = "account/admin/user-management/user-details?userId=" + this.userId;
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.service.hideSpinner();
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }

  // add faq language



  showData: any

  fieroPrice: any
  getFieroPrice() {
    let url = `wallet/get-sale-list`
    this.service.get(url).subscribe((res: any) => {
      if (res.status == 200) {
        this.fieroPrice = res.data['enabled_sale']['price']
        if (res.data.enabled_sale.icoStatus == "ENABLE") {
          this.fieroPrices = res.data['enabled_sale']['priceWithoutMark']
          this.finalPrice = res.data['enabled_sale']['price']
        }
        if (this.addFaqForm.value.salePrice === 'TRANC') {
          // if (this.markTab == 'UP' || this.markTab == 'DOWN') {
          //   this.addFaqForm.patchValue({
          //     tokenPrice: this.finalPrice
          //   })
          // } else {
          //   this.addFaqForm.patchValue({
          //     tokenPrice: this.fieroPrices
          //   })
          // }
          if(this.markTab === this.planDetails.markType){
            this.addFaqForm.patchValue({
              'tokenPrice': this.planDetails.tokenPrice.toFixed(2),
            })
          }
          // else{
          //   if (this.markTab == 'UP' || this.markTab == 'DOWN') {
          //     this.addFaqForm.patchValue({
          //       tokenPrice: this.finalPrice
          //     })
          //   } else {
          //     this.addFaqForm.patchValue({
          //       tokenPrice: this.fieroPrices
          //     })
          //   }
          // }
        }

       
      } else {

      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  viewPlan() {
    this.service.get(`wallet/view-vesting-plans?id=${this.planId}`).subscribe((res: any) => {
      if (res['status'] = 200) {
        this.planDetails = res.data
        const startDate = new Date(this.planDetails.startDate);
        const startDateString = startDate.toISOString().split('T')[0];
        const endDate = new Date(this.planDetails.endDate);
        const endDateString = endDate.toISOString().split('T')[0];
        this.addFaqForm.patchValue({
          'planName': this.planDetails.planName,
          'tokenPrice': this.planDetails.tokenPrice.toFixed(2),
          'tokenSellLimit': this.planDetails.sellLimit,
          'terms': this.planDetails.terms,
          'withdrawaCommission': this.planDetails.comission,
          'releaseTimePeriod': this.planDetails.releaseTerm,
          'planStartdate': startDateString,
          'planEndDate': endDateString,
          // 'oldPlanOldUser': this.planDetails.oldPlanOldUser,
          'newPlanOldUser': this.planDetails.newPlanOldUser,
          // 'oldPlanNewUser': this.planDetails.oldPlanNewUser,
          'newPlanNewUser': this.planDetails.newPlanNewUser,
          'salePrice': this.planDetails.vestingPlanType,
          "minGbpAmount": this.planDetails.minGbpAmount,

        })
        this.enable = this.planDetails.markStatus,
        this.markStatus = this.planDetails.markType,
        this.markDownPercentage = this.planDetails.markDownPercentage;
        this.markUpPercentage = this.planDetails.markUpPercentage;
          this.myImage = this.planDetails.image
          this.markTab  = this.planDetails.markType
          // if (this.addFaqForm.value.salePrice === 'TRANC') {
          //   if (this.markTab == 'UP' || this.markTab == 'DOWN') {
          //     this.addFaqForm.patchValue({
          //       tokenPrice: this.planDetails.tokenPrice
          //     })
          //   } else {
          //     this.addFaqForm.patchValue({
          //       tokenPrice: this.planDetails.tokenPrice
          //     })
          //   }
          // }
        this.getFieroPrice();

      } else {
        this.service.toasterErr(res['message'])
      }
    }, (err => {
      this.service.toasterErr(err['message'])
    }))
  }
  enable: any
  markStatus: any
  addVestingPlan() {
    // let url = `wallet/admin/update-vesting?newUserType=${this.addFaqForm.value.}&oldUserType=OLD&sellLimit=10&tokenPrice=10&vestingPlanId=${this.planId}`
    // let body = {
    //   "sellLimit": this.addFaqForm.value.tokenSellLimit,
    //   "tokenPrice": this.addFaqForm.value.tokenPrice
    // }
    this.enable
    this.markStatus
    if (this.addFaqForm.value.salePrice === 'TRANC') {
      this.enable = 'ENABLE'
      this.markStatus = this.markTab
    } else {
      this.enable = 'DISABLE'
      this.markDownPercentage = 0;
      this.markUpPercentage = 0;
      this.markStatus = 'NOMARK'
    }
    const originalDateString = this.addFaqForm.value.planEndDate; // Assuming it's a valid date string
    const planEndDateISOString = new Date(originalDateString).toISOString();
    this.service.showSpinner()
    let url = `wallet/admin/update-vesting?vestingPlanId=${this.planId}`
    let body = {
      "endDate": this.addFaqForm.value.planEndDate,
      "image": this.myImage,
      "newPlanNewUser": this.addFaqForm.value.newPlanNewUser,
      "newPlanOldUser": this.addFaqForm.value.newPlanOldUser,
      "minGbpAmount": this.addFaqForm.value.minGbpAmount,
      // "oldPlanNewUser": this.addFaqForm.value.oldPlanNewUser,
      // "oldPlanOldUser": this.addFaqForm.value.oldPlanOldUser,
      "sellLimit": this.addFaqForm.value.tokenSellLimit,
      "tokenPrice": this.addFaqForm.value.tokenPrice,
      "vestingPlanType": this.addFaqForm.value.salePrice,
      "markDownPercentage": this.markTab == "NOMARK" ? 0 : (this.markTab == "DOWN" ? this.markDownPercentage : 0),
      "markStatus": this.enable,
      "markType": this.markStatus,
      "markUpPercentage": this.markTab == "NOMARK" ? 0 : (this.markTab == "UP" ? this.markUpPercentage : 0),

    }
    this.service.put(url, body).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.toasterSucc(res.message)
        this.route.navigate(['/vesting-plan-list'])
        this.service.hideSpinner()
      } else {
        this.service.hideSpinner()

      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  myImageFIle: any
  myImageNew: any
  myImage: any
  onFileSelected(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement.files[0];

    if (file) {
      this.previewImage(file);
    }
  }
  previewImage(file: File) {
    const reader = new FileReader();
    reader.onload = () => {
      this.myImageNew = reader.result;
    };
    $('#imgCheck').modal('show')
    reader.readAsDataURL(file);
    this.myImageFIle = file
  }
  imageUplaod() {
    this.uploadImageFunc(this.myImageFIle);
  }
  /** to call form data */
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.service.showSpinner();
    this.service.postApi('account/upload-file', fb).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == '200') {
        this.myImage = res['data'];
        $('#imgCheck').modal('hide')

      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  dataWhiteList: any = []
  emailString: any
  getWhiteListedUser() {
    let url = `account/admin/user-management/get-whitelisted?planId=${this.planId}`
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {

          this.dataWhiteList = res['data']
          this.emailString = this.dataWhiteList.map(item => item.email).join(',');
          this.modalForm.patchValue({
            address: this.emailString
          })
          this.service.hideSpinner();
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );

  }
  getBlackListUser() {
    let url = `account/admin/user-management/get-blackList?planId=${this.planId}`
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {

          this.dataWhiteList = res['data']
          this.emailString = this.dataWhiteList.map(item => item.email).join(',');
          this.modalFormBlacklist.patchValue({
            emailAddress: this.emailString
          })
          this.service.hideSpinner();
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );

  }
  openModal(type) {
    if(type == 'Whitelist'){
      $("#addPlanModal").modal("show");
    }else{
      $("#addBlacklist").modal("show");
    }
  }
  addWhiteList() {

    const emailString = [this.modalForm.value.address]
    const value = emailString[0]; // Assuming yourObject contains the data you provided
    const emailArray = value.split(',');
    const trimmedEmailArray = emailArray.map(email => email.trim());
    const body = {
      "email": trimmedEmailArray
    };
    this.service.showSpinner()
    let url = `account/admin/user-management/add-vesting-whitelist?planId=${this.planId}`
    this.service.put(url, body).subscribe((res: any) => {
      if (res.status == 200) {
        $("#addPlanModal").modal("hide");
        this.service.toasterSucc(res['message'])
        this.getWhiteListedUser()
    this.service.showSpinner()

      } else {
    this.service.showSpinner()
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  addBlackListFuc() {

    const emailString = [this.modalFormBlacklist.value.emailAddress]
    const value = emailString[0]; // Assuming yourObject contains the data you provided

    const emailArray = value.split(',');
    const trimmedEmailArray = emailArray.map(email => email.trim());
    const body = {
      "email": trimmedEmailArray
    };
    let url = `account/admin/user-management/add-vesting-blockList?planId=${this.planId}`
    this.service.put(url, body).subscribe((res: any) => {
      if (res.status == 200) {
        $("#addBlacklist").modal("hide");
        this.service.toasterSucc(res['message'])
      } else {
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }


  // markup and markdown value

  markDownPercentage: any
  markUpPercentage: any
  priceType = ""
  viewFee() {
    /* this.service.showSpinner(); */
    var url = "wallet/coin/get-coin-details?coinName=FIERO"
    this.service.showSpinner()
    this.service.get(url).subscribe((res: any) => {
      this.service.hideSpinner()
      if (res.status == 200) {
        this.priceType = res.data.markStatus == 'ENABLE' ? 'MARK' : 'TRANCHES'
        // this.markTab = res.data.markType
        this.percentageValue = this.markTab == 'UP' ? res.data.markUpPercentage : this.markTab == 'DOWN' ? res.data.markDownPercentage : 0
        this.markDownPercentage = res.data.markDownPercentage
        this.markUpPercentage = res.data.markUpPercentage
      }
    }, (err) => {
      if (err.status == 401) {
        this.service.hideSpinner();
        this.service.toasterErr('Unauthorized access')
      }
      else {
        this.service.toasterErr('something went wrong')
      }
    })
  }
  coinList: any = [];
  cointAmount: any = []
  price: any = []
  startDate: any = []
  endDate: any = []
  supply: any = []
  usd: any = []
  fieroPrices: number
  finalPrice: any

  markTab = ''
  setMarkTab(e) {
    this.markTab = e
    this.setFinalPrice(Number(this.markTab == 'UP' ? this.markUpPercentage : this.markDownPercentage))
  }
  percentageValue: any
  percentage: any
  setMarkupPercentage() {
    this.percentage = 0
    if (this.markTab == 'UP') {
      this.percentage = this.markUpPercentage
    }
    else if (this.markTab == 'DOWN') {
      this.percentage = this.markDownPercentage
    }
    $('#markUpModal').modal('hide')
    // this.getFieroPrice()     
    if (this.addFaqForm.value.salePrice === 'TRANC') {
      if (this.markTab == 'UP' || this.markTab == 'DOWN') {
        this.addFaqForm.patchValue({
          tokenPrice: this.finalPrice
        })
      } else {
        this.addFaqForm.patchValue({
          tokenPrice: this.fieroPrices
        })
      }
    }
  }
  setFinalPrice(e: number) {
    e = e > 100 ? 100 : e < 0 ? 0 : e
    if (this.markTab == 'UP') {
      this.finalPrice = Number(this.fieroPrices + (this.fieroPrices * e / 100)).toFixed(2)
    }
    else if (this.markTab == 'DOWN') {
      this.finalPrice = Number(this.fieroPrices - (this.fieroPrices * e / 100)).toFixed(2)
    }
    else {
      this.finalPrice = this.fieroPrices
    }
  }
  toFixed(number, toLength: number) {
    return Number(number).toFixed(toLength)
  }
}
