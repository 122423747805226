<main class="middle-content">
    <div class="content-section">
        <div class="page_title_block pL0 pR0">
            <h1 class="page_title" style="color:#26272d">View Wallet Transaction
            </h1>
        </div>



        <!-- tab General -->
        <div class="tab-pane active show">
            <div class="order-view mt30">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style">
                        <!-- <div class="form-group row">
                            <div class="col-md-12 text-center">
                                <img [src]="userDetail?.imageUrl || 'assets/img/profile-img.jpg'" alt="" style="    border-radius: 50%;
                              ">
                            </div>
                        </div> -->
                        <div class="form-group row">
                            <div class="col-5">
                                <label class="labelName">Transaction Type: </label>
                            </div>
                            <div class="col-2 text-center">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p>Deposit</p>
                            </div>

                            <!-- <div class="col-md-1">:</div>
                            <div class="col-md-7">
                                <label>{{userDetail?.firstName}} {{userDetail?.lastName}}</label>
                            </div> -->
                        </div>
                        <div class="form-group row">
                            <div class="col-5">
                                <label class="labelName">Transaction Id:

                                </label>
                            </div>
                            <div class="col-2 text-center">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p>19875678</p>
                            </div>

                            <!-- <label class="col-md-4"></label>
                            <div class="col-md-1">:</div>
                            <div class="col-md-7">
                                <label class="user-eml"></label>
                            </div> -->
                        </div>
                        <div class="form-group row">
                            <div class="col-5">
                                <label class="labelName">Transaction Fee:
                                </label>
                            </div>
                            <div class="col-2 text-center">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p>1089

                                </p>
                            </div>

                            <!-- <label class="col-md-4"></label>
                            <div class="col-md-1">:</div>
                            <div class="col-md-7">
                                <label>{{userDetail?.phoneNo || 'N/A'}}</label>
                            </div> -->
                        </div>


                        <!-- <div class="form-group row">
                            <div class="col-5">
                                <label class="labelName"><b>Two Factor Type</b></label>
                            </div>
                            <div class="col-2 text-center">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p>{{userDetail?.twoFaType || 'N/A'}}</p>
                            </div> -->
                        <!-- <label class="col-md-4"></label>
                            <div class="col-md-1">:</div>
                            <div class="col-md-7">
                                <label>{{userDetail?.twoFaType || 'N/A'}}</label>
                            </div> -->
                        <!-- </div> -->

                        <div class="form-group row">
                            <div class="col-5">
                                <label class="labelName">Transaction Hash:
                                </label>
                            </div>
                            <div class="col-2 text-center">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p>d519302bh</p>
                            </div>
                            <!-- <label class="col-md-4">Address </label>
                            <div class="col-md-1">:</div>
                            <div class="col-md-7">
                                <p class="rap2">{{userDetail?.address || 'N/A'}}</p>
                            </div> -->
                        </div>
                        <div class="form-group row">
                            <div class="col-5">
                                <label class="labelName">To Address: </label>
                            </div>
                            <div class="col-2 text-center">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p>d519302bhs3456fn </p>
                            </div>
                            <!-- <label class="col-md-4">Address </label>
                            <div class="col-md-1">:</div>
                            <div class="col-md-7">
                                <p class="rap2">{{userDetail?.address || 'N/A'}}</p>
                            </div> -->
                        </div>
                        <div class="form-group row">
                            <div class="col-5">
                                <label class="labelName">From Address: </label>
                            </div>
                            <div class="col-2 text-center">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p>d519302bhs3456fn</p>
                            </div>
                            <!-- <label class="col-md-4">Address </label>
                            <div class="col-md-1">:</div>
                            <div class="col-md-7">
                                <p class="rap2">{{userDetail?.address || 'N/A'}}</p>
                            </div> -->
                        </div>

                        <div class="form-group row">
                            <div class="col-5">
                                <label class="labelName">Transaction Date & Time: </label>
                            </div>
                            <div class="col-2 text-center">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p>15 April 2023, 12:45 pm</p>
                                <!-- <p>Government ID</p> -->
                            </div>

                            <!-- <label class="col-md-4">KYC Doc </label>
                            <div class="col-md-1">:</div>
                            <div class="col-md-7">
                                <p class="rap2">{{userKyc?.docName || 'N/A'}}</p>
                            </div> -->
                        </div>
                        <!-- <div class="form-group row" *ngIf="userDetail?.kyc?.document[0]?.documentStatus == 'REJECTED' || userDetail?.kyc?.document[0]?.documentStatus == 'PENDING'">
                            <div class="col-5">
                                <label class="labelName"><b>KYC Doc</b></label>
                            </div>
                            <div class="col-2 text-center">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p> Not Accepted</p>
                            </div> -->

                        <!-- <label class="col-md-4">KYC Doc </label>
                            <div class="col-md-1">:</div>
                            <div class="col-md-7">
                                <p class="rap2">{{userKyc?.docName || 'N/A'}}</p>
                            </div> -->
                        <!-- </div> -->
                        <div class="form-group row">
                            <div class="col-5">
                                <label class="labelName">Amount: </label>
                            </div>
                            <div class="col-2 text-center">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p>0.001</p>
                            </div>
                            <!-- <label class="col-md-4">KYC Doc ID </label>
                            <div class="col-md-1">:</div>
                            <div class="col-md-7">
                                <p class="rap2">{{userKyc?.docIdNumber || 'N/A'}}</p>
                            </div> -->
                        </div>

                        <div class="form-group row">
                            <div class="col-5">
                                <label class="labelName">Currency:</label>
                            </div>
                            <div class="col-2 text-center">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p>BNB</p>
                            </div>
                            <!-- <label class="col-md-4">KYC Doc ID </label>
                            <div class="col-md-1">:</div>
                            <div class="col-md-7">
                                <p class="rap2">{{userKyc?.docIdNumber || 'N/A'}}</p>
                            </div> -->
                        </div>
                        <div class="form-group row">
                            <div class="col-5">
                                <label class="labelName">Transaction Status: </label>
                            </div>
                            <div class="col-2 text-center">
                                <span>:</span>
                            </div>
                            <div class="col-5">
                                <p>Successfull</p>
                            </div>
                            <!-- <label class="col-md-4">KYC Doc ID </label>
                            <div class="col-md-1">:</div>
                            <div class="col-md-7">
                                <p class="rap2">{{userKyc?.docIdNumber || 'N/A'}}</p>
                            </div> -->
                        </div>

                    </div>
                    <div class="text-left mt40 text-center">
                        <a routerLink="/user-management" class="btn btn-large  max-WT-200 font-100 btn-green">Back</a>
                    </div>
                </div>
            </div>
        </div>






    </div>
</main>
<footer>
    <div class="container-fluid">
        <p class="copyright" style="margin-right: -3%">FIERES ICO Crypto © 2023 All Rights Reserved.
            <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>