<main class="middle-content">
    <div class="content-section">
        <div class="page_title_block pL0 pR0">
            <h1 class="page_title" style="color:#26272d">View Token</h1>
        </div>
        <div class="tab-pane active show">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style">

                        <div class="form-group row">
                            <label class="col-md-5 text" for="">Token Name</label>
                            <span class="col-md-1">:</span>
                            <div class="col-md-6">
                                <p>ABC token</p>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-5 text" for="">Token ID</label>
                            <span class="col-md-1">:</span>
                            <div class="col-md-6">
                                <p>2457XX</p>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-5 text" for="">Token Address</label>
                            <span class="col-md-1">:</span>
                            <div class="col-md-6">
                                <p>token address</p>
                            </div>

                        </div>
                        <div class="form-group row">
                            <label class="col-md-5 text" for="">Price</label>
                            <span class="col-md-1">:</span>
                            <div class="col-md-6">
                                <p>$125</p>
                            </div>
                        </div>



                        <div class="text-left mt40 text-center">
                            <a routerLink="/token-management"
                                class="btn btn-large  max-WT-200 font-100 btn-green">Back</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</main>
<footer>
    <div class="container-fluid">
        <p class="copyright">FIERES ICO Crypto © 2023 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>