import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MainService } from "src/app/provider/main.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ngxCsv } from "ngx-csv/ngx-csv";


declare var $
@Component({
  selector: "app-user-details",
  templateUrl: "./user-details.component.html",
  styleUrls: ["./user-details.component.css"],
})
export class UserDetailsComponent implements OnInit {
  walletForm: FormGroup;
  currTab: any = "GI";
  id: any = 2;
  userDetail: any = [];
  tradingDetail: any;
  loginSessionData: any;
  pageNumber: number = 1;
  coinData: any = [];
  userKyc: any = [];
  url: string;
  loylity: FormGroup
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public service: MainService
  ) {
    this.route.params.subscribe((params) => {
      this.id = params.id;
    });
  }

  ngOnInit() {
    this.walletvalidation();
    this.getFormValue()
    this.coinList();

    this.showUserDetail();
  }

  coinList() {
    var url =
      "wallet/coin/get-coin-list?page=" +
      (this.pageNumber - 1) +
      "&pageSize=10";
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.coinData = res["data"];
        }
      },
      (err) => {
        this.service.hideSpinner(); ''
        if (err["status"] == 401) {
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }

  selectTab(tab) {
    this.currTab = tab;
    if (this.currTab === "WalletTrading") {
      // this.router.navigate(['wallet-management'])
    } else if (this.currTab === "Trading") {
      this.userTrading();
    } else if (this.currTab === "Login") {
      this.loginSession();
    } else if (this.currTab === "GI") {
      this.showUserDetail();
    }
  }

  walletvalidation() {
    this.walletForm = new FormGroup({
      startdate: new FormControl("", Validators.required),
      enddate: new FormControl("", Validators.required),
      currency: new FormControl(""),
      type: new FormControl(""),
    });
  }
  getFormValue() {
    this.loylity = new FormGroup({
      loyalityAmount: new FormControl('')
    })
  }

  // api of Trading
  userTrading() {
    var url =
      "wallet/admin-basic-exchange/get-user-trading-history?page=" +
      (this.pageNumber - 1) +
      "&pageSize=10" +
      "&userId=" +
      this.id;
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.tradingDetail = res["data"]["list"];
        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }

  // search
  search() {
    let type = this.walletForm.value.type;
    let currency = this.walletForm.value.currency;
    let startdate = Date.parse(this.walletForm.value.startdate);
    let enddate = Date.parse(this.walletForm.value.enddate);
    if (
      this.walletForm.value.enddate &&
      this.walletForm.value.currency &&
      this.walletForm.value.type
    ) {
      this.url =
        "wallet/admin-basic-exchange/get-user-trading-history?page=" +
        (this.pageNumber - 1) +
        "&pageSize=20" +
        "&userId=" +
        this.id +
        "&orderType=" +
        type +
        "&coin=" +
        currency +
        "&fromDate=" +
        startdate +
        "&toDate=" +
        enddate;
    } else if (
      this.walletForm.value.startdate &&
      this.walletForm.value.enddate
    ) {
      this.url =
        "wallet/admin-basic-exchange/get-user-trading-history?page=" +
        (this.pageNumber - 1) +
        "&pageSize=20" +
        "&fromDate=" +
        startdate +
        "&toDate=" +
        enddate +
        "&userId=" +
        this.id;
    } else if (
      this.walletForm.value.enddate &&
      this.walletForm.value.currency
    ) {
      this.url =
        "wallet/admin-basic-exchange/get-user-trading-history?page=" +
        (this.pageNumber - 1) +
        "&pageSize=20" +
        "&coin=" +
        currency +
        "&fromDate=" +
        startdate +
        "&toDate=" +
        enddate +
        "&userId=" +
        this.id;
    } else if (this.walletForm.value.startdate) {
      this.url =
        "wallet/admin-basic-exchange/get-user-trading-history?page=" +
        (this.pageNumber - 1) +
        "&pageSize=20" +
        "&fromDate=" +
        startdate +
        "&userId=" +
        this.id;
    } else if (this.walletForm.value.type && this.walletForm.value.currency) {
      this.url =
        "wallet/admin-basic-exchange/get-user-trading-history?page=" +
        (this.pageNumber - 1) +
        "&pageSize=20" +
        "&userId=" +
        this.id +
        "&coin=" +
        currency +
        "&orderType=" +
        type;
    } else if (this.walletForm.value.type) {
      this.url =
        "wallet/admin-basic-exchange/get-user-trading-history?page=" +
        (this.pageNumber - 1) +
        "&pageSize=20" +
        "&userId=" +
        this.id +
        "&orderType=" +
        type;
    } else if (this.walletForm.value.currency) {
      this.url =
        "wallet/admin-basic-exchange/get-user-trading-history?page=" +
        (this.pageNumber - 1) +
        "&pageSize=20" +
        "&userId=" +
        this.id +
        "&coin=" +
        currency;
    }

    this.service.showSpinner();
    this.service.get(this.url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.tradingDetail = res["data"]["list"];
        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }

  reset() {
    if (
      this.walletForm.value.enddate ||
      this.walletForm.value.currency ||
      this.walletForm.value.type
    ) {
      this.userTrading();
      this.walletForm.reset();
    }
  }

  // view user trading navigation
  viewUserTrading(id) {
    this.router.navigate(["/view-user-trading-detail"], {
      queryParams: { id: id },
    });
  }

  // api of general information
  showUserDetail() {
    this.service.showSpinner();
    var url = "account/admin/user-management/user-details?userId=" + this.id;
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.userDetail = res.data;
          this.userKyc = res?.data?.kyc?.document[0];
          this.service.hideSpinner();
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }

  // Api of login session activity
  loginSession() {
    this.service.showSpinner();
    var url =
      "account/admin/user-management/get-user-last-login-activity?userId=" +
      this.id;
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.service.hideSpinner();
          this.loginSessionData = res.data;
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err.status == 401) {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something went wrong");
        }
      }
    );
  }

  //export User
  exportAsXLSX() {
    let dataArr = [];
    this.tradingDetail.forEach((element, ind) => {
      dataArr.push({
        "S no": ind + 1,
        "Transaction ID": element.basicTradeHistoryId
          ? element.basicTradeHistoryId
          : "",
        "Transaction Type": element.orderType ? element.orderType : "",
        "Base Coin": element.baseCoinName ? element.baseCoinName : "N/A",
        "Executable Coin": element.execCoinName ? element.execCoinName : "N/A",
        Amount: element.baseCoinAmmount ? element.baseCoinAmmount : "N/A",
        Date: element.creationTime ? element.creationTime.slice(0, 10) : "N/A",
      });
    });

    this.service.exportAsExcelFile(dataArr, "TRADING MANAGEMENT");
  }

  //export User
  userDetailsExportAsXLSX() {
    let dataArr = [];
    // this.userDetail.forEach((element, ind) => {

    //   dataArr.push({
    //     "Name": element.firstName + '' + element.lastName ? element.lastName : '',
    //     "Email": element.email ? element.email : '',
    //     "Mobile Number": element.phoneNo ? element.phoneNo : '',
    //     "Country": element.country ? element.country : 'N/A',
    //     "KYC Doc": element.kyc?.document[0]?.docName ? element.kyc?.document[0]?.docName : 'N/A',
    //     "KYC Doc ID": element.kyc?.document[0]?.docIdNumber ? element.kyc?.document[0]?.docIdNumber : 'N/A' ,
    //   })
    // })
    let keyArr = ['firstname', 'lastname', 'email', 'mobilenumber', 'country']
    for (let item of Object.entries(this.userDetail)) {
      console.log(item[0]);

      if (keyArr.includes(String(item[0]).toLowerCase())) {
        let temp = {}
        temp[item[0]] = item[1]
        dataArr.push(temp)
      }
      else if (String(item[0]).toLowerCase().includes('kyc')) {

        let temp = {}
        temp['KYC Doc'] = 'Government ID',
          dataArr.push(temp)
        temp = {}
        temp['KYC Doc ID'] = item[1]?.['document'][0]['docIdNumber']
        dataArr.push(temp)
      }

    }
    console.log(dataArr);

    let dataArr2 = [{
      "Name": dataArr[1].firstName + ' ' + dataArr[2].lastName,
      "Email": dataArr[0].email,
      "Country": dataArr[3].country,
      "KYC Doc": 'Government ID',
      "KYC Doc ID": dataArr[5]['KYC Doc ID']
    }]
    // dataArr.forEach((element, ind) => {

    //       dataArr2.push({
    //         "Name": element.firstName + '' + element.lastName ? element.lastName : '',
    //         "Email": element.email ? element.email : '',
    //         "Mobile Number": element.phoneNo ? element.phoneNo : '',
    //         "Country": element.country ? element.country : 'N/A',
    //         "KYC Doc": element.kyc?.document[0]?.docName ? element.kyc?.document[0]?.docName : 'N/A',
    //         "KYC Doc ID": element.kyc?.document[0]?.docIdNumber ? element.kyc?.document[0]?.docIdNumber : 'N/A' ,
    //       })
    //     })
    console.log(dataArr2);

    this.service.exportAsExcelFile(dataArr2, "USER DETAILS");
    console.log(dataArr);
  }

  currWalletTab: any = "Deposite";
  selectWalletTab(e) {
    this.currWalletTab = e;
  }
  withdrawlData: any = [];
  depositeData: any = [];


  shortAddress(address) {
    const shortedValue = `${address.slice(0, 6)}....${address.slice(address.length - 4)}`
    return shortedValue
  }
  copyToClipboard(item) {
    navigator.clipboard.writeText(item).then(
      () => {
        this.service.toasterSucc('Copied')
      },
      () => {
        this.service.toasterErr('Failed')
      }
    );
  }
  volume = ''
  openTransferModal() {
    $('#transferAmount').modal('show')
  }
  transferLoyltyMoney() {
    this.service.showSpinner()
    let url = `wallet/vesting-management/admin/loyality-amount?ibiId=${this.userDetail.ibiId}&ibiName=${this.userDetail.ibiName}&loyalityAmount=${this.loylity.value.loyalityAmount}&userId=${this.userDetail.userId}&volume=${this.volume}`
    this.service.post(url, {}).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.loginSessionData = res.data;
          $('#transferAmount').modal('hide')
          this.service.toasterSucc(res['message'])
          this.service.hideSpinner();
        } else {
          $('#transferAmount').modal('hide')
          this.service.toasterSucc(res['message'])
          this.service.hideSpinner();
        }
      },
      (err) => {
        $('#transferAmount').modal('hide')
        this.service.hideSpinner();
        this.service.toasterSucc(err['message'])
        if (err.status == 401) {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something went wrong");
        }
      }
    );
  }
  getDOB(t){
    if(String(t).includes('-')){
      return new Date(t).toISOString()
    }
    var utcSeconds = t;

    var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setHours(12)
    d.setMinutes(0)
     d.setUTCSeconds(utcSeconds);

     return new Date(d).toISOString()
  }
}
