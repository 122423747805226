import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { countryJson } from 'src/assets/country';
declare var $
@Component({
  selector: 'app-transaction-view-management',
  templateUrl: './transaction-view-management.component.html',
  styleUrls: ['./transaction-view-management.component.css']
})
export class TransactionViewManagementComponent implements OnInit {

  modalForm: FormGroup;
  userId: any;
  viewData: any;
  documentId: any;
  kycId: any;
  kycStatus: any;
  ima: any;
  docData: any;
  fileExten:any;
  txnType:any
  temp = "http://www.africau.edu/images/default/sample.pdf";
  constructor(
    public router: Router,
    public service: MainService,
    public active: ActivatedRoute,
    public sanitizer: DomSanitizer
  ) {
    this.active.queryParams.subscribe((params) => {
      
      this.userId = params.id;
      this.txnType = params.txnType;
    });
  }

  ngOnInit(): void {
    this.viewKyc();

    this.modalForm = new FormGroup({
      reason: new FormControl("", Validators.required),
    });
  }
  userDetail: any;
  showUserDetail(id) {
    this.service.showSpinner();
    var url =
      "account/admin/user-management/user-details?userId=" + id;
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.userDetail = res.data;
          this.service.hideSpinner();
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
  // Api of view kyc
  viewKyc() {
    var url =
      "wallet/get-transaction-details?txnId=" + this.userId;
    this.service.get(url).subscribe(
      (res: any) => {
        console.log("view", res);
        if (res.status == 200) {
          this.viewData = res.data
           this.showUserDetail(res.data.fkUserId)
         
       if(this.txnType != 'REWARDED'){
        this.approveKyc(res.data.paymentId)
       }
        
        }
      },
      (err) => {
        if (err["status"] == 401) {
          this.service.toasterErr("Unauthorized Access.");
        } else {
          this.service.toasterErr("Something went wrong.");
        }
      }
    );
  }

  approved() {
    if (this.kycStatus == "ACCEPTED") {
      this.service.toasterInfo("Already kyc approved.");
    } else {
      $("#approveModal").modal("show");
    }
  }

  rejected() {
    if (this.kycStatus == "REJECTED") {
      this.service.toasterInfo("Already kyc rejected.");
    } else {
      $("#rejectModal").modal("show");
    }
  }
  updatedTime : any

  paymentDetail : any
  approveKyc(id) {
    this.service.showSpinner();
 
    this.service
      .get(`wallet/vesting-management/view-by-paymentId?paymentId=${id}`)
      .subscribe(
        (res: any) => {

          if (res.status == 200) {
            this.paymentDetail = res.data
           this.updatedTime = res.data.updateDate
          } else {
            this.service.hideSpinner();
            this.service.toasterErr(res.message);
          }
        },
        (error) => {
          this.service.hideSpinner();
        }
      );
  }

  rejectKyc() {
    this.service.showSpinner();
    let data = {
      documentId: this.documentId,
      kycId: this.kycId,
      reason: this.modalForm.value.reason,
      status: "REJECTED",
    };
    this.service
      .post("account/admin/kyc-management/doc-status", data)
      .subscribe(
        (res: any) => {
          console.log("hjuygidsu67tdsuk", res);
          if (res.status == 200) {
            console.log("jkdhsgdfgvs87dfdksjfhg", res);
            this.service.hideSpinner();
            // this.service.toasterSucc(res.message)
            this.service.toasterSucc("KYC rejected successfully.");
            $("#rejectModal").modal("hide");
            this.router.navigate(["/kyc-update"]);
          } else {
            this.service.toasterErr(res.message);
            this.service.hideSpinner();
          }
        },
        (error) => {
          this.service.hideSpinner();
        }
      );
  }

  image() {
    $("#imgg").modal("show");
  }

  backimage() {
    $("#backimgg").modal("show");
  }
  getCountryCode(e){
    let find =  obj.country.find((el)=>{
       return el.name == e
     })
     return find.code
   }

}
let obj = new countryJson()