import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { countryJson } from 'src/assets/country';
declare var $
@Component({
  selector: 'app-transaction-list-management',
  templateUrl: './transaction-list-management.component.html',
  styleUrls: ['./transaction-list-management.component.css']
})
export class TransactionListManagementComponent implements OnInit {

  userForm: FormGroup;
  listing: any = [];
  rebarded: any
  id: number;
  deleted: any;
  totalRecords: any;
  pageNumber: number = 1;
  itemsPerPage: number = 10;
  userid: any;
  userStatus: any;
  isAssending: boolean = true;
  // today = new Date().toISOString().split("T")[0];
  today: any = new Date().toISOString().slice(0, 16)
  sortType : any = "DESC"
  type : any = "DATE"
  permissionArr: any = {}

  constructor(
    private router: Router,
    public service: MainService,
    private httpClient: HttpClient,
    private date: DatePipe
  ) {
    this.service.permission.subscribe((res) => {
      if (Object.keys(res).length) {
        this.permissionArr = res
        console.log(res);

      }
    })
  }

  ngOnInit() {
    this.userForm = new FormGroup({
      startdate: new FormControl(""),
      enddate: new FormControl(""),
      searchText: new FormControl(""),
      country: new FormControl(""),
      userStatus: new FormControl(""),
      ramdomId: new FormControl(""),
      firstName: new FormControl(""),
      lastName: new FormControl(""),
      ibiid: new FormControl(""),
      address: new FormControl(""),
      vestingPlan: new FormControl("")
    });
    this.getlist();
    this.countryList();
    this.getListFiero();
    this.getClaimData();
  }

  vestingPlanList: any = []
  getListFiero() {
    let url = `wallet/vesting-plans`
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      this.vestingPlanList = res.data
      // this.totalRecords = res.data.size;
    })
    this.service.hideSpinner()
  }
  products: any = [];
  countryList() {
    this.httpClient.get("assets/country.json").subscribe((data) => {
      // console.log(data);
      this.products = data;
      // console.log(this.products)
    });
  }

  isSearched: boolean = false;
  newValue(e) {
    this.rebarded = e
    this.getlist()
  }

  getlist() {
    let startdate = Date.parse(this.userForm.value.startdate);

    let enddate = Date.parse(this.userForm.value.enddate);
    // let startdate = this.userForm.value.startdate;

    // let enddate = this.userForm.value.enddate;

    var search = this.userForm.value.searchText;
    let countryDa = this.userForm.value.country;
    let ramdomId = this.userForm.value.ramdomId;
    let firstName = this.userForm.value.firstName;
    let lastName = this.userForm.value.lastName;
    let ibiId = this.userForm.value.ibiid;
    let planId = this.userForm.value.vestingPlan;
    let address = this.userForm.value.address;
    let rewardValue = this.rebarded
    let url = `wallet/get-all-transaction-history?page=${this.pageNumber - 1}&pageSize=${this.itemsPerPage}${this.userForm.value.searchText ? "&userEmail=" + search : ""}${this.userForm.value.startdate ? "&fromDate=" + startdate : ""}${this.userForm.value.enddate ? "&toDate=" + enddate : ""}${this.userForm.value.country ? "&country=" + countryDa : ""}${this.userForm.value.address ? "&address=" + address : ""}${this.userForm.value.userStatus ? "&status=" + this.userForm.value.userStatus : ""}${this.userForm.value.ramdomId ? "&randomId=" + ramdomId : ''}${firstName ? '&firstName=' + firstName : ''}${lastName ? '&lastName=' + lastName : ''}${ibiId ? '&ibiId=' + ibiId : ''}${planId ? '&planId=' + planId : ''}${rewardValue ? '&txnType=' + rewardValue : '&txnType=' + 'BUY'}`;

    // if (this.sortType == 'desc') {
    //   this.pageNumber =1
    //    url = `wallet/get-all-transaction-history?page=${this.pageNumber - 1}&pageSize=${this.totalRecords}${this.userForm.value.searchText ? "&userEmail=" + search : ""}${this.userForm.value.startdate ? "&fromDate=" + startdate : ""}${this.userForm.value.enddate ? "&toDate=" + enddate : ""}${this.userForm.value.country ? "&country=" + countryDa : ""}${this.userForm.value.userStatus ? "&status=" + this.userForm.value.userStatus : ""}${this.userForm.value.ramdomId ? "&randomId=" + ramdomId : ''}${firstName ? '&firstName=' + firstName : ''}${lastName ? '&lastName=' + lastName : ''}${ibiId ? '&ibiId=' + ibiId : ''}${planId ? '&planId=' + planId : ''}${rewardValue ? '&txnType=' + rewardValue : '&txnType=' + 'BUY'}`;

    // }
    url += `&dType=${this.type}&sort=${this.sortType}`
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          // if (this.sortType == 'desc') {
          //   this.listing = []
          //   let listing = res.data.resultlist.sort((b,a)=>{
          //     return b.updateDate - a.updateDate
          //   })
          //   let filter = listing.filter((ele)=>{
          //     return ele.updateDate != 'NA'
          //   })
          //   let filter2 = listing.filter((ele)=>{
          //     return ele.updateDate == 'NA'
          //   })
          //   this.listing = filter.concat(filter2)
          // }
          // else{
          //   this.listing = res.data.resultlist;
          //   this.totalRecords = res.data.totalCount;
          // }
          this.listing = res.data.resultlist;
            this.totalRecords = res.data.totalCount;

          this.service.hideSpinner();
        }

      },
      (err: any) => {
        this.service.hideSpinner();
        this.service.toasterErr(err.error.message);
      }
    );
  }

  sortAgent(key) {
    this.type = key
    this.sortType = this.sortType == 'ASC' ? 'DESC' : 'ASC'
    this.getlist()
  }
  pagination(page) {
    // this.totalRecords = [];
    console.log("jh", page);
    this.pageNumber = page;
    console.log("jh", this.pageNumber);
    this.getlist();
    if (this.sortType == 'desc') {


      // this.search();
    } else {
     
    }
  }
  changeSort(key) {
    this.sortType = this.sortType == 'desc' ? 'asc' : 'desc'
    this.getlist()
  }
  search() {
    let startdate = Date.parse(this.userForm.value.startdate);
    let enddate = Date.parse(this.userForm.value.enddate);
    // let startdate =   this.userForm.value.startdate ? new Date(this.userForm.value.startdate).toISOString() : '';
    // let enddate = this.userForm.value.enddate ? new Date(this.userForm.value.enddate).toISOString() : '';
    var search = this.userForm.value.searchText;
    let countryDa = this.userForm.value.country;

    let url = `account/admin/user-management/filter-user-details?page=${this.pageNumber - 1
      }&pageSize=10
    ${this.userForm.value.searchText
        ? "&search=" + search
        : ""
      } 
    ${this.userForm.value.startdate ? "&fromDate=" + startdate : ""}
    ${this.userForm.value.enddate ? "&toDate=" + enddate : ""}
    ${this.userForm.value.country ? "&country=" + countryDa : ""}
    ${this.userForm.value.userStatus
        ? "&status=" + this.userForm.value.userStatus
        : ""
      }
    `;

    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        this.service.hideSpinner();
        this.listing = res.data.list;
        console.log("kfg", this.listing);
        // this.totalRecords = res.data.totalCount;
        this.totalRecords = res.data.totalCount;
      },
      (err) => {
        this.service.hideSpinner();
        // this.listing = [];

        // this.totalRecords = err.data.totalCount;
      }
    );
  }

  // reset
  // reset() {
  //   if (
  //     (this.userForm.value.startdate && this.userForm.value.enddate) ||
  //    ( this.userForm.value.searchText  ) || this.userForm.value.country
  //   ) {
  //     this.userForm.reset();
  //     this.getlist();
  //   }
  // }

  reset() {
    if (
      this.userForm.value.startdate ||
      this.userForm.value.enddate ||
      this.userForm.value.searchText ||
      this.userForm.value.ramdomId ||
      this.userForm.value.userStatus ||
      this.userForm.value.firstName ||
      this.userForm.value.lastName ||
      this.userForm.value.ibiid || this.userForm.value.vestingPlan ||  this.rebarded
    ) {
      this.userForm.reset({
        startdate: "",
        userStatus: "",
        enddate: "",
        searchText: "",
        country: "",
        ramdomId: "",
        firstName: "",
        lastName: "",
        ibiid: "",
        vestingPlan : ""
      });
      this.rebarded = ''
      this.getlist();
    }
  }

  //========modal=======//
  delete(id: number) {
    this.id = id;
    $("#deleteModal").modal("show");
  }
  deleteUser() {
    this.service
      .get(`account/admin/user-management/delete-user-detail?userId=${this.id}`)
      .subscribe(
        (res: any) => {
          this.deleted = res;
          if ((this.deleted.ststus = 200)) {
            $("#deleteModal").modal("hide");
            this.service.toasterSucc(this.deleted.message);
            this.getlist();
          }
        },
        (err) => {
          /* this.service.hideSpinner();  */ if (err["status"] == "401") {
            this.service.onLogout();
            this.service.toasterErr("Unauthorized Access");
          } else {
            this.service.toasterErr("Something Went Wrong");
          }
        }
      );
  }

  block(status, id) {
    this.userid = id;
    this.userStatus = status;
    $("#block").modal("show");
  }
  blockUser() {
    /* this.service.showSpinner(); */
    var url =
      "account/admin/user-management/user-status?userStatus=" +
      this.userStatus +
      "&userId=" +
      this.userid;
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        this.service.hideSpinner();
        if (res.status == 200) {
          this.service.toasterSucc(res.message);
          /*  this.service.hideSpinner();  */ this.getlist();
          $("#block").modal("hide");
        }
      },
      (err) => {
        /*  this.service.hideSpinner();   */
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
  // user details navigation
  userDetails(id) {
    this.router.navigate(["/user-details"], { queryParams: { id: id } });
  }

  //export User
  async exportAsXLSX() {
    let temp = [];
    let startdate = Date.parse(this.userForm.value.startdate);

    let enddate = Date.parse(this.userForm.value.enddate);
    var search = this.userForm.value.searchText;

    let ramdomId = this.userForm.value.ramdomId;
    let firstName = this.userForm.value.firstName;
    let lastName = this.userForm.value.lastName;
    let ibiId = this.userForm.value.ibiid;
    let planId = this.userForm.value.vestingPlan;
    let address = this.userForm.value.address;
    let rewardValue = this.rebarded


    let countryDa = this.userForm.value.country;
    let url = `wallet/get-all-transaction-history?page=${0}&pageSize=${this.totalRecords}${this.userForm.value.searchText ? "&userEmail=" + search : ""}${this.userForm.value.startdate ? "&fromDate=" + startdate : ""}${this.userForm.value.enddate ? "&toDate=" + enddate : ""}${this.userForm.value.country ? "&country=" + countryDa : ""}${this.userForm.value.address ? "&address=" + address : ""}${this.userForm.value.userStatus ? "&status=" + this.userForm.value.userStatus : ""}${this.userForm.value.ramdomId ? "&randomId=" + ramdomId : ''}${firstName ? '&firstName=' + firstName : ''}${lastName ? '&lastName=' + lastName : ''}${ibiId ? '&ibiId=' + ibiId : ''}${planId ? '&planId=' + planId : ''}${rewardValue ? '&txnType=' + rewardValue : '&txnType=' + 'BUY'}`;
    url += `&dType=${this.type}&sort=${this.sortType}`
    this.service.showSpinner();
    try {
      let res: any = await this.service.get(url).toPromise();

      this.service.hideSpinner();
      if (res.status == 200) {
        let dataArr = [];
        res.data.resultlist.forEach((element, ind) => {
          dataArr.push({
            "S no": ind + 1,
            "Type": element.txnType ? element.txnType : "N/A",
            "First Name": element.firstName ? element.firstName : "N/A",
            "Last Name": element.lastName ? element.lastName : "N/A",
            "Address": element.address ? element.address : "N/A",
            "Plan Name": element.planData.planName ? element.planData.planName : "N/A",
            "Txn Created At": element.txnTime ? this.date.transform(element.txnTime, 'medium') : "N/A",
            "Txn Status Updated At": element?.updateDate == null ? 'N/A' : element?.updateDate == 'NA' ? 'N/A' : this.date.transform(element.updateDate, 'medium'),
            "Email Id	": element.userEmail ? element.userEmail : "N/A",
            "Fieres allocated": element.amount ? element.amount : "N/A",
            "GBP Amount": element.gbpAmount ? element.gbpAmount : "N/A",
            "Currency": element.baseCurrency ? `${element.usdtAmount} ${element.baseCurrency}` : "N/A",
            "Random Id": element.randomId ? element.randomId : "N/A",
            "IBI Id": element.ibiId ? element.ibiId : "N/A",
            "IBI Name": element.ibiName ? element.ibiName : "N/A",
            "Fiero Price": element.lastFieroPrice ? this.getLivePrice(element.lastFieroPrice) : "N/A",
            "Volume": element.voulme ? element.voulme : "N/A",
            "Claim Request": element.claimRequest ? element.claimRequest : "N/A",
            "Country": element.country ? this.getCountryCode(element.country) : "N/A",
            "Payment Status": element.status ? element.status : "N/A"
          });
          console.log(this.getCountryCode(element.country), ind);
          
        });

        this.service.exportAsExcelFile(dataArr, "Transaction list");
        console.log(dataArr);
      }
    } catch (error) {
      console.log(error)
      this.service.hideSpinner();
      this.service.toasterErr(error.error.message);
    }


  }
  getLivePrice(e){
    if(e){
      return e
    }
    else{
      return 'N/A'
    }
  }

  tokenId: any
  approved(id) {
    this.tokenId = id
    $("#approveModal").modal("show");
  }
  approveKyc() {
    this.service.showSpinner();
    const url = `wallet/vesting-management/admin/claim-token?tokenRequest=CONFIRM&txnId=${this.tokenId}`
    this.service.put(url, {}).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner();
        this.getlist();
        this.service.toasterSucc("Accepted successfully.");
        $("#approveModal").modal("hide");
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message);
      }
    },
      (error) => {
        this.service.hideSpinner();
      }
    );
  }
  getCountryCode(e) {
    let find = obj.country.find((el) => {
      return el.name == e
    })
    if(find){
      return find.code
    }
    else{
      return e
    }
   
  }
  userDetailData:any
  getClaimData(){
    let url = `wallet/vesting-management/admin/get-claim-data`
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.userDetailData = res['data']
      } else {
        // this.service.toasterErr(res['message']);
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Session Expired');
      } else {
        // this.service.toasterErr('Something Went Wrong');
      }
    })
  }
}
let obj = new countryJson()