<!-- <main class="middle-content"> -->
<div class="page_title_block" style="padding: 0px 0 22px 17px;">
    <h1 class="page_title" style="color:#26272d; ">VIEW KYC DETAILS</h1>
</div>
<div class="content-section1">

    <div class="outer-box">
        <div class="global-table no-radius p0">
            <div class="tab-content1">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-5 col-md-6 col-sm-12 doc_detail">
                            <div class="row mb20p">
                                <div class="col-12 text-left">
                                    <h5 style="color: #fff">Document Detail</h5>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-5">
                                    <p class="sub_head">User Name</p>
                                </div>
                                <div class="col-2 text-center">
                                    <span>:</span>
                                </div>
                                <div class="col-5">
                                    <p class="sub_title">{{userDetail?.firstName}} {{userDetail?.lastName}}</p>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-5">
                                    <p class="sub_head">Phone Number</p>
                                </div>
                                <div class="col-2 text-center">
                                    <span>:</span>
                                </div>
                                <div class="col-5">
                                    <p class="sub_title">{{userDetail?.phoneNo}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-5">
                                    <p class="sub_head">Email Address</p>
                                </div>
                                <div class="col-2 text-center">
                                    <span>:</span>
                                </div>
                                <div class="col-5">
                                    <p class="sub_title">{{userDetail?.email}}</p>
                                </div>
                            </div>

                            <!-- <div class="row">
                                <div class="col-5">
                                    <p class="sub_head">Two Factor Type
                                    </p>
                                </div>
                                <div class="col-2 text-center">
                                    <span>:</span>
                                </div>
                                <div class="col-5">
                                    <p class="sub_title">{{userDetail?.twoFaType}}</p>
                                </div>
                            </div> -->

                            <!-- <div class="row">
                                <div class="col-5">
                                    <p class="sub_head"> KYC ID</p>
                                </div>
                                <div class="col-2 text-center">
                                    <span>:</span>
                                </div>
                                <div class="col-5">
                                    <p class="sub_title">{{kycId}}</p>
                                </div>
                            </div> -->


                            <div class="row">
                                <div class="col-5">
                                    <p class="sub_head">KYC Document Number</p>
                                </div>
                                <div class="col-2 text-center">
                                    <span>:</span>
                                </div>
                                <div class="col-5">
                                    <p class="sub_title">{{viewData?.documentNumber}}</p>
                                </div>
                            </div>

                            <!-- <div class="row">
                                <div class="col-5">
                                    <p class="sub_head">KYC Document</p>
                                </div>
                                <div class="col-2 text-center">
                                    <span>:</span>
                                </div>
                                <div class="col-5">
                                    <p class="sub_title">{{viewData?.docName}}</p>
                                </div>
                            </div> -->
                            <div class="row">
                                <div class="col-5">
                                 
                                    <p class="sub_head">KYC Document Id</p>

                                </div>
                                <div class="col-2 text-center">
                                    <span>:</span>
                                </div>
                                <div class="col-5">
                                    <p class="sub_title">{{viewData?.docName}}</p>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-5">
                                 
                                    <p class="sub_head">D.O.B.</p>

                                </div>
                                <div class="col-2 text-center">
                                    <span>:</span>
                                </div>
                                <div class="col-5">
                                    <p class="sub_title">{{(getDate(userDetail?.dob) | date : 'dd-MMM-yyyy') || '--'}}</p>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-5">
                                 
                                    <p class="sub_head">Address</p>

                                </div>
                                <div class="col-2 text-center">
                                    <span>:</span>
                                </div>
                                <div class="col-5">
                                    <p class="sub_title">{{userDetail?.address}}</p>

                                </div>
                            </div>
                            <!-- <div class="form-group row" *ngIf="viewData?.documentStatus == 'REJECTED' || viewData?.documentStatus == 'PENDING'">
                                <div class="col-5">
                                    <p class="sub_head">KYC Document</p>

                                </div>
                                <div class="col-2 text-center">
                                    <span>:</span>
                                </div>
                                <div class="col-5">
                                    <p class="sub_title">Not Accepted</p>

                                </div>
                                    </div> -->
                            <!-- <div class="row">
                                <div class="col-5">
                                    <p class="sub_head">KYC Status</p>
                                </div>
                                <div class="col-2 text-center">
                                    <span>:</span>
                                </div>
                                <div class="col-5">
                                    <p class="sub_title">{{kycStatus == 'REJECTED' ? 'REVISED' : kycStatus}}</p>
                                </div>
                            </div> -->

                            <div class="row">
                                <div class="col-5">
                                    <p class="sub_head">Document Status</p>
                                </div>
                                <div class="col-2 text-center">
                                    <span>:</span>
                                </div>
                                <div class="col-5">
                                    <p class="sub_title">{{viewData?.documentStatus }}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-5">
                                    <p class="sub_head">Date & Time</p>
                                </div>
                                <div class="col-2 text-center">
                                    <span>:</span>
                                </div>
                                <div class="col-5">
                                    <p class="sub_title">{{viewData?.createTime | date:'medium'}}</p>
                                </div>
                            </div>



                        </div>
                        <div class="col-lg-7 col-md-6 col-sm-12">
                            <div class="row mb20p">
                                <div class="col-12 text-center">
                                    <h5>Document Image</h5>
                                </div>

                            </div>
                            <div class="row mb-3">

                                <div class="col-6 ">

                                    <div class="text-center document_image" (click)="image()">
                                        <h6 class="mb15p">Front Image</h6>
                                        <img *ngIf="fileExten != 'pdf'"
                                            src="{{viewData?.frontIdUrl ? viewData?.frontIdUrl : 'assets/img/noimg.png' }}"
                                            alt="" class="kyc-img">


                                        <object *ngIf="fileExten == 'pdf'" class="kyc-img"
                                            [data]="sanitizer?.bypassSecurityTrustResourceUrl(viewData?.frontIdUrl)"
                                            type="application/pdf,application/png,application/jpeg,application/jpg"
                                            width="100%" height="100%">
                                            <iframe class="kyc-img"
                                                [src]="sanitizer?.bypassSecurityTrustResourceUrl(viewData?.frontIdUrl)"
                                                width="100%" height="100%" style="border: none;">
                                                <p>
                                                    Your browser does not support PDFs.
                                                    <a
                                                        [href]="sanitizer?.bypassSecurityTrustResourceUrl(viewData?.frontIdUrl)">Download
                                                        the PDF</a>
                                                    .
                                                </p>
                                            </iframe>
                                        </object>
                                    </div>
                                </div>
                                <div class="col-6 ">
                                    <div *ngIf="viewData?.backIdUrl" class="text-center document_image" (click)="backimage()">
                                        <h6 class="mb15p">Back Image</h6>
                                        <img *ngIf="fileExten != 'pdf'"
                                            src="{{viewData?.backIdUrl ? viewData?.backIdUrl : 'assets/img/noimg.png' }}"
                                            alt="" class="kyc-img">
                                        <object *ngIf="fileExten == 'pdf'" class="kyc-img"
                                            [data]="sanitizer?.bypassSecurityTrustResourceUrl(viewData?.frontIdUrl)"
                                            type="application/pdf,application/png,application/jpeg,application/jpg"
                                            width="100%" height="100%">
                                            <iframe class="kyc-img"
                                                [src]="sanitizer?.bypassSecurityTrustResourceUrl(viewData?.frontIdUrl)"
                                                width="100%" height="100%" style="border: none;">
                                                <p>
                                                    Your browser does not support PDFs.
                                                    <a
                                                        [href]="sanitizer?.bypassSecurityTrustResourceUrl(viewData?.frontIdUrl)">Download
                                                        the PDF</a>
                                                    .
                                                </p>
                                            </iframe>
                                        </object>
                                    </div>
                                </div>
                            </div>

                            <!-- <hr> -->
                            <br>

                            <div class="row ">

                                <div class="col-6 ">
                                    <div class="text-center document_image" (click)="selfeimage()">
                                        <h6 class="mb15p">Selfie Image</h6>
                                        <img *ngIf="fileExten != 'pdf'"
                                        src="{{viewData?.selfieUrl ? viewData?.selfieUrl : 'assets/img/noimg.png' }}"
                                            alt="" class="kyc-img">
                                    
                                    </div>
                                </div>
                                <div class="col-6 ">
                                    <div class="text-center document_image" (click)="residenc()">
                                        <h6 class="mb15p">Address Proof</h6>
                                        <img src="{{viewData?.residenceProof ? viewData?.residenceProof : 'assets/img/noimg.png' }}"
                                        alt="" class="kyc-img">
                                    </div>
                                </div>



                              
                            </div>
                            
                        </div>
                        <!-- <div class="col-lg-12 col-md-12 col-sm-12 mt-3">
                                    <div class="row mb20p">
                                        <div class="col-12 text-center">
                                            <h5>Selfie Image</h5>
                                        </div>

                                    </div>
                                    <div class="row mb-3">

                                        <div class="col-12 ">

                                            <div class="text-center document_image" >
                                                <img 
                                                    src="{{viewData?.selfieUrl ? viewData?.selfieUrl : 'assets/img/noimg.png' }}"
                                                    alt="" class="kyc-img">

                                            </div>
                                        </div>
                                    </div>


                                </div> -->
<!-- 
                        <div class="col-lg-12 col-md-12 col-sm-12 mt-3">
                            <div class="row mb20p">
                                <div class="col-12 text-left">
                                    <h5>Address Proof</h5>
                                </div>

                            </div>
                            <div class="row mb-3" (click)="residenc()">

                                <div class="col-12 ">

                                    <div class="text-left document_image" style="width: 100%">
                                        <img src="{{viewData?.residenceProof ? viewData?.residenceProof : 'assets/img/noimg.png' }}"
                                            alt="" class="kyc-img">

                                    </div>
                                </div>
                            </div>


                        </div> -->
                    </div>
                </div>
                <div class="btnn">
                    <div class="two-btnline">
                        <ng-container  *ngIf="service.isShow(permissionArr,'KYC_MANAGEMENT','UPDATE')">
                        <div *ngIf="viewData?.documentStatus =='PENDING' " class="text-left">
                            <button type="submit" class="btn  btn-theme cus-mr" (click)="approved()">Approve</button>
                        </div>
                        <div  class="text-left">
                            <button type="submit" class="btn  btn-theme cus-mr " (click)="rejected()">Reject</button>
                        </div>
                        </ng-container>
                        <div class="text-left">
                            <button type="submit" class="btn  btn-theme cus-mr"
                                routerLink="/user-management">Back</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>
<!-- </main> -->

<footer>
    <div class="container-fluid">
        <p class="copyright">FIERES ICO Crypto © 2023 All Rights Reserved.<strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>
<!-- appraved_modal Start -->
<div class="modal fade global-modal reset-modal" id="approveModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">KYC Approve</h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <!-- <p style="font-size: 25px;">Are you sure you want to approve kyc with data entered?</p> -->
                                <p style="font-size: 25px;">Are you sure you want to approve this kyc?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2 " style="width: 25%;"
                                        (click)="approveKyc()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- appraved_modal End -->
<!-- rejected_modal Start -->
<div class="modal fade global-modal reset-modal" id="rejectModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password" [formGroup]="modalForm">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 20px;">Are you sure you want to reject this kyc? </p>

                                <div class="row" style="margin-bottom: 10px;">
                                    <div class="col-md-4 ">
                                        <p style="font-size: 16px;">Reason:</p>
                                    </div>
                                    <div class="col-md-8">
                                        <textarea formControlName="reason" class="form-control"
                                            id="exampleFormControlTextarea1" rows="3" style="height: 115px;"></textarea>

                                    </div>

                                </div>
                                <!-- <div style="margin-top: 2%;margin-bottom: 2%; margin-right: 13%;">
                                    <input class="form-check-input" style="margin-top: 2%;" type="checkbox" value=""
                                        id="Notify User">

                                </div> -->
                                <div style="margin-left: 27%;">
                                    <button type="submit" class="btn btn-info mr-2 " style="width: 25%;"
                                        (click)="rejectKyc()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- rejected_modal End -->
<!--image view modal-->
<div class="modal fade global-modal reset-modal mgpo" id="imgg" style="width: 60%;margin-left:25% ;">
    <div class="modal-dialog max-WT-500">
        <div class="modal-content">
            <div>

                <div class="row align-items-center modal_flax_height">
                    <div class="col" style="text-align: center; font-size:25px;">
                        <i class="fas fa-times white" data-dismiss="modal" style="float: right;  margin-right: 2px;"
                            aria-hidden="true"></i>
                        <strong class="white">Front Image</strong>
                        <img src="{{viewData?.frontIdUrl ? viewData?.frontIdUrl : 'assets/img/noimg.png' }}" alt=""
                            style="width: 100%;z-index: 1;">

                    </div>

                </div>

            </div>
            <!--   -->

        </div>
    </div>
</div>

<div class="modal fade global-modal reset-modal mgpo" id="backimgg" style="width: 60%;margin-left:25% ;">
    <div class="modal-dialog max-WT-500">
        <div class="modal-content">
            <div>

                <div class="row align-items-center modal_flax_height">
                    <div class="col" style="text-align: center; font-size:25px;">
                        <i class="fas fa-times white" data-dismiss="modal" style="float: right;  margin-right: 2px;"
                            aria-hidden="true"></i>

                        <strong class="white">Back Image</strong>

                        <img src="{{viewData?.backIdUrl ? viewData?.backIdUrl : 'assets/img/noimg.png' }}" alt=""
                            style="width: 100%;z-index: 1;">

                    </div>

                </div>

            </div>
            <!--   -->

        </div>
    </div>
</div>

<div class="modal fade global-modal reset-modal mgpo" id="selfImg" style="width: 60%;margin-left:25% ;">
    <div class="modal-dialog max-WT-500">
        <div class="modal-content">
            <div>

                <div class="row align-items-center modal_flax_height">
                    <div class="col" style="text-align: center; font-size:25px;">
                        <i class="fas fa-times white" data-dismiss="modal" style="float: right;  margin-right: 2px;"
                            aria-hidden="true"></i>

                        <strong class="white">Selfie Image</strong>

                        <img src="{{viewData?.selfieUrl ? viewData?.selfieUrl : 'assets/img/noimg.png' }}" alt=""
                            style="width: 100%;z-index: 1;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade global-modal reset-modal mgpo" id="residencImg" style="width: 60%;margin-left:25% ;">
    <div class="modal-dialog max-WT-500">
        <div class="modal-content">
            <div>

                <div class="row align-items-center modal_flax_height">
                    <div class="col" style="text-align: center; font-size:25px;">
                        <i class="fas fa-times white" data-dismiss="modal" style="float: right;  margin-right: 2px;"
                            aria-hidden="true"></i>

                        <strong class="white">Address Proof</strong>

                        <img src="{{viewData?.residenceProof ? viewData?.residenceProof : 'assets/img/noimg.png' }}"
                            alt="" style="width: 100%;z-index: 1;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>