<div class="wrapper">

    <!-- Aside End -->
    <main class="middle-content">
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title" style="color:black">Edit Tutorial</h1>

        </div>
        <!-- Page Title End -->
        <div class="content-section">
            <div class="order-view mt30">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style">
                        <form [formGroup]="addFaqForm">
                            <div class="form-group row ">
                              <label class="col-md-4">Title :</label>
                              <div class="col-md-8 col-sm-12">
                                <input type="text" class="form-control " formControlName="title"
                                  (keypress)="service.preventSpace($event)" />
              
                                <span class="error">
                                  <p *ngIf="addFaqForm.get('title').hasError('required') && (addFaqForm.get('title').dirty || addFaqForm.get('title').touched )"
                                    class="error" padding>*Title is required</p>
                                </span>
                                <!-- <span class="error">
                                  <p *ngIf="addFaqForm.get('title').hasError('pattern') && (addFaqForm.get('title').dirty || addFaqForm.get('title').touched )"
                                    class="error" padding>*Please enter valid Title</p>
                                </span> -->
                              </div>
              
                            </div>
                            <div class="form-group row ">
                              <label class="col-md-4">Language :</label>
                              <div class="col-md-8 col-sm-12">
                               
                                <select class="form-control " formControlName="language">
                                  <option [value]="item.name" *ngFor="let item of languageArrs">{{item.name}}&nbsp;{{item.nativeName}}</option>
                                </select>
                                <span class="error">
                                  <p *ngIf="addFaqForm.get('language').hasError('required') && (addFaqForm.get('language').dirty || addFaqForm.get('language').touched )"
                                    class="error" padding>*Language is required</p>
                                </span>
                               
                              </div>
              
                            </div>
                            <div class="form-group row ">
                              <label class="col-md-4">Image :</label>
                              <div class="col-md-8 col-sm-12">
                                <input type="file" class="form-control " (change)="uploadImg($event)" formControlName="image" />
                                <img *ngIf="editImage" [src]="editImage" alt="">
                                <!-- <span class="error">
                                  <p *ngIf="addFaqForm.get('image').hasError('required') && (addFaqForm.get('image').dirty || addFaqForm.get('image').touched )"
                                    class="error" padding>*Image is required</p>
                                </span> -->
                               <!--  <span class="error">
                                  <p *ngIf="addFaqForm.get('title').hasError('pattern') && (addFaqForm.get('title').dirty || addFaqForm.get('title').touched )"
                                    class="error" padding>*Please enter valid Title</p>
                                </span> -->
                              </div>
              
                            </div>
                            <div class="form-group row ">
                                <label class="col-md-4">Video Url :</label>
                                <div class="col-md-8 col-sm-12">
                                  <input type="text" class="form-control " formControlName="videoUrl"
                                    (keypress)="service.preventSpace($event)" />
                                  <span class="error">
                                    <p *ngIf="addFaqForm.get('videoUrl').hasError('required') && (addFaqForm.get('videoUrl').dirty || addFaqForm.get('videoUrl').touched )"
                                      class="error" padding>*Video url is required</p>
                                  </span>
                                </div>
                
                              </div>
                              <div class="form-group row ">
                                <label class="col-md-4">Button Url :</label>
                                <div class="col-md-8 col-sm-12">
                                  <input type="text" class="form-control " formControlName="url"
                                    (keypress)="service.preventSpace($event)" />
                                  <!-- <span class="error">
                                    <p *ngIf="addFaqForm.get('url').hasError('required') && (addFaqForm.get('url').dirty || addFaqForm.get('url').touched )"
                                      class="error" padding>*Url is required</p>
                                  </span> -->
                                </div>
                
                              </div>
                            <div class="form-group row">
              
                              <label class="col-md-4">Description:</label>
                              <div class="col-md-8 col-sm-12">
                                  <!-- <div *ngIf="description" class="col-md-12"> -->
                                  <div  class="col-md-12">
            
                                    <ck-editor name="editor1" formControlName="description" debounce="500" skin="moono-lisa"
                                        language="en" [fullPage]="true"></ck-editor>
            
                                </div>
                                <span class="error">
                                  <p *ngIf="addFaqForm.get('description').hasError('required') && (addFaqForm.get('description').dirty || addFaqForm.get('description').touched )"
                                    class="error" padding>*Description is required</p>
                                </span>
              
              
                              </div>
                            </div>
                            <div class="text-center mt40" style="margin-right: -14%;">
                              <button class="btn btn-large  max-WT-200 font-100 btn-green mr-5"
                                (click)="updateFaq()">Update</button>
                              <a class="btn btn-large  max-WT-200 font-100 btn-grey ml8" [routerLink]="['/fieres-tutorial-list']">Cancel</a>
                            </div>
                          </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <!-- Middle Content End -->
</div>

<footer>
    <div class="container-fluid">
        <p class="copyright">FIERES ICO Crypto © 2023 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>