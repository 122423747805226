<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">KYC UPDATE</h1>
    </div>

    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">

                        <!-- <div class="user-main-head"> -->
                        <div class="user-main-head sec_head_new sec_head_new_after">

                            <!-- <p
                                style="margin-top: -3%;font-size: 124%;background-color: white; width: 10%; padding-left: 1%;">
                                Filter By</p> -->
                            <form [formGroup]="kycForm" style=" margin-top: 1%">
                                <div class="row form-group">
                                    <!-- <span style=" margin-top: 1%">Search By</span> -->

                                    <!-- <div class="col-md-1" style="padding-top: 2%;">
                                        <label for="" style="white-space: nowrap">Search By:</label>
                                    </div> -->
                                    <!-- <div class="col-md-2" style="padding-top: 1%;">
                                        <select formControlName="status" class="form-control form1">
                                            <option value="">Select status</option>
                                            <option>PENDING</option>
                                            <option>ACCEPTED</option>
                                            <option>REJECTED</option>
                                        </select>
                                    </div> -->
                                    <div class="col-md-3">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group">
                                                <select formControlName="status" class="form-control form1">
                                                    <option value="">Select Status</option>
                                                    <option value="PENDING">PENDING</option>
                                                    <option value="ACCEPTED">ACCEPTED</option>
                                                    <option value="REJECTED">REJECTED</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group">
                                                <select formControlName="country" class="form-control form1">
                                                    <option value="">Select Country</option>
                                                    <option *ngFor="let code of products" value="{{code.name}}">
                                                        {{code?.name}}</option>


                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-5 col-sm-12 user-lst">
                                        <div class="">
                                            <div class="text-left">
                                                <input type="text" placeholder="Username/Email"
                                                    class="form-control form " formControlName="userName">
                                            </div>
                                        </div>
                                    </div>


                                    <!-- <div class="col-md-2 col-sm-6 user-lst" >
                                        <div class="two-btnline">
                                            <div class="text-left" style="margin-left: 1%" (click)="search()">
                                                <button type="submit" class="btn  btn-theme cus-mr"
                                                    >Search</button>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div class="col-md-2 col-sm-6 user-lst">
                                      
                                        <div class="two-btnline sm-left">
                                            <div class="text-left" style="margin-left: 5px" (click)="reset()">
                                                <button type="submit" class="btn  btn-theme cus-mr"
                                                    >Reset</button>
                                            </div>
                                        </div>
                                    </div> -->




                                </div>
                                <div class="col-md-12 user-lst p-0">
                                    <div class="two-btnline" style="margin-top: 3px;    justify-content: end;">
                                        <div class="text-left" style="margin-left: 1%">
                                            <button type="submit" class="btn  btn-theme cus-mr"
                                                (click)="pageNumber = 1;kyc()">Apply

                                            </button>
                                        </div>
                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label" style="margin-left: 1%">
                                                <button type="submit" class="btn  btn-theme" (click)="reset()">Clear
                                                </button>
                                            </div>
                                        </div>
                                        <div class="head_flt_select ml-2" *ngIf="service.isShow(permissionArr,'KYC_MANAGEMENT','UPDATE')">
                                            <div class="head_flt_select input-label" style="margin-left: 1%">
                                                <button type="submit" class="btn  btn-theme "
                                                    (click)="exportAsXLSX()">EXPORT AS EXCEL
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </form>
                        </div>
                        <!-- <div class="col-md-12 col-sm-12 exprt">

                            <button type="submit" class="btn  btn-theme " (click)="exportAsXLSX()">EXPORT AS EXCEL
                            </button>
                        </div> -->

                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="user list">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th scope="col">S.No</th>
                                        <th scope="col">Username <i class="fa fa-sort"
                                                (click)="sortAgent('NAME');isAssending=!isAssending"
                                                aria-hidden="true" style="cursor: pointer;"></i>
                                        </th>
                                        <th scope="col">Email <i (click)="sortAgent('EMAIL');isAssending=!isAssending"
                                                class="fa fa-sort" style="cursor: pointer;" aria-hidden="true"
                                                style="cursor: pointer;"></i>
                                        </th>
                                        <th scope="col">Mobile Number <i
                                                (click)="sortAgent('MOBILE');isAssending=!isAssending"
                                                class="fa fa-sort" style="cursor: pointer;" aria-hidden="true"
                                                style="cursor: pointer;"></i>
                                        </th>
                                        <!-- <th scope="col">Document ID <i
                                                (click)="sortAgent('kycId');isAssending=!isAssending" class="fa fa-sort"
                                                style="cursor: pointer;" aria-hidden="true"
                                                style="cursor: pointer;"></i>
                                        </th> -->
                                        <th scope="col">Country <i
                                                (click)="sortAgent('COUNTRY');isAssending=!isAssending"
                                                class="fa fa-sort" style="cursor: pointer;" aria-hidden="true"
                                                style="cursor: pointer;"></i>
                                        </th>
                                        <th scope="col">Date & Time <i
                                                (click)="sortAgent('DATE');isAssending=!isAssending"
                                                class="fa fa-sort" style="cursor: pointer;" aria-hidden="true"
                                                style="cursor: pointer;"></i>
                                        </th>
                                        <th scope="col">Status
                                        </th>
                                        <th scope="col" class="action_td_btn3">Action</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="kycList?.length">
                                    <tr
                                        *ngFor="let data of kycList | paginate: {itemsPerPage:itemsPerPage, currentPage:pageNumber, totalItems:totalRecords}; let i=index">
                                        <td>{{itemsPerPage*(this.pageNumber-1)+i+1}}</td>
                                        <td class="name">{{data.firstName || 'N/A'}} {{data?.lastName}}</td>
                                        <td>{{data.email || 'N/A'}}</td>
                                        <td>{{data.phoneNO || 'N/A'}}</td>
                                        <!-- <td>{{data.kycId || 'N/A'}}</td> -->
                                        <td>{{data.country}}</td>
                                        <td>{{data.updateTime | date :'medium'}}</td>
                                        <!-- <td>{{data.kycStatus || 'N/A'}}</td> -->
                                        <td>{{data.kycStatus == 'REJECTED' ? 'REJECTED' : data.kycStatus || 'N/A'}}</td>
                                        <td class="action_td_btn3" routerLink="/kyc-detail/{{data.userId}}">
                                            <a><em class="fa fa-eye"></em></a>
                                        </td>
                                    </tr>

                                </tbody>
                                <tbody *ngIf="!kycList?.length">
                                    <tr>
                                        <td colspan="10" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5>No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                            *ngIf="totalRecords > 10">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<footer>
    <div class="container-fluid">
        <p class="copyright">FIERES ICO Crypto © 2023 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>