<main class="middle-content">
    <div class="content-section">
        <div class="page_title_block pL0 pR0">
            <h1 class="page_title" style="color:#26272d">VIEW PLAN </h1>
        </div>

        <!-- tab General -->
        <div class="tab-pane active show">
            <div class="order-view mt30">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style">
                     <div class="row">
                       <div class="col-md-6">
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Plan Name</label>
                            <div class="col-md-1 sm-d-none">:</div>
                            <div class="col-md-7">
                                <label>{{planDetails?.planName}}</label>
                            </div>

                        </div>
                       </div>
                       <div class="col-md-6">
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Withdrawal Commission</label>
                            <div class="col-md-1 sm-d-none">:</div>
                            <div class="col-md-7">
                                <label>{{planDetails?.comission}}</label>
                            </div>
                        </div>
                    </div>
                     </div>
                       <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-4">Token Price</label>
                                <div class="col-md-1 sm-d-none">:</div>
                                <div class="col-md-7">
                                    <label class="user-eml">{{planDetails?.tokenPrice}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-4">Token Sell Limit</label>
                                <div class="col-md-1 sm-d-none">:</div>
                                <div class="col-md-7">
                                    <label style="word-break: break-word;">{{planDetails?.sellLimit}}</label>
                                </div>
                            </div>
                        </div>
                       </div>

                       <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-4">Locked Period (Days)</label>
                                <div class="col-md-1 sm-d-none">:</div>
                                <div class="col-md-7">
                                    <label class="user-eml">{{planDetails?.terms}}</label>
                                </div>
                            </div>
                          
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-4">Release Period (Days)</label>
                                <div class="col-md-1 sm-d-none">:</div>
                                <div class="col-md-7">
                                    <label class="user-eml">{{planDetails?.releaseTerm}}</label>
                                </div>
                            </div>
                        </div>
                       </div>
                     <div class="row">
                       <div class="col-md-6" *ngIf="planDetails?.newPlanNewUser">
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">New User </label>
                            <div class="col-md-1 sm-d-none">:</div>
                            <div class="col-md-7">
                                <span >
                                    <p>Plan Show</p>
                                </span>
                            </div>
                        </div>
                       </div>
                       <div class="col-md-6"  *ngIf="planDetails?.newPlanOldUser">
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Old User </label>
                            <div class="col-md-1 sm-d-none">:</div>
                            <div class="col-md-7">
                                <span >
                                    <p >Plan Show</p>
                                </span>
                            </div>
                        </div>

                       </div>
                     </div>
                       

                      <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-4">Plan Start Date</label>
                                <div class="col-md-1 sm-d-none">:</div>
                                <div class="col-md-7">
                                    <label class="user-eml">{{planDetails?.startDate | date :'medium'}}</label>
                                </div>
                            </div>
                           </div>
                           <div class="col-md-6">
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-4">Plan End Date</label>
                                <div class="col-md-1 sm-d-none">:</div>
                                <div class="col-md-7">
                                    <label class="user-eml">{{planDetails?.endDate | date :'medium'}}</label>
                                </div>
                            </div>
                           </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-4">Image</label>
                                <div class="col-md-1 sm-d-none">:</div>
                                <div class="col-md-7">
                                   <img [src]="planDetails?.image" alt="">
                                </div>
                            </div>
                         
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-4">Vesting Plan Type</label>
                                <div class="col-md-1 sm-d-none">:</div>
                                <div class="col-md-7">
                                    <label class="user-eml">{{planDetails?.vestingPlanType}}</label>
                                </div>
                            </div>
                         
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-4">Mark Status</label>
                                <div class="col-md-1 sm-d-none">:</div>
                                <div class="col-md-7">
                                    <label class="user-eml">{{planDetails?.markStatus}}</label>

                                </div>
                            </div>
                         
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-4">Mark Type</label>
                                <div class="col-md-1 sm-d-none">:</div>
                                <div class="col-md-7">
                                    <label class="user-eml">{{planDetails?.markType}}</label>
                                </div>
                            </div>
                         
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-4">Mark Up Percentage</label>
                                <div class="col-md-1 sm-d-none">:</div>
                                <div class="col-md-7">
                                    <label class="user-eml">{{planDetails?.markUpPercentage}}</label>
                                </div>
                            </div>
                         
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-4">Mark Down Percentage</label>
                                <div class="col-md-1 sm-d-none">:</div>
                                <div class="col-md-7">
                                    <label class="user-eml">{{planDetails?.markDownPercentage}}</label>

                                </div>
                            </div>
                         
                        </div>
                        
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-4">Minimum GBP Amount</label>
                                <div class="col-md-1 sm-d-none">:</div>
                                <div class="col-md-7">
                                    <label class="user-eml">{{planDetails?.minGbpAmount}}</label>

                                </div>
                            </div>
                         
                        </div>
                      </div>
                      <div class="btnn">
                        <div class="two-btnline">
                            <div class="text-left pt-4">
                                <button type="submit" class="btn  btn-theme cus-mr"
                                    routerLink="/vesting-plan-list">Back</button>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- tab genral end -->


        <!-- tab login session end -->

    </div>
</main>
<footer style="margin-top: 8%;">
    <div class="container-fluid">
        <p class="copyright">FIERES ICO Crypto © 2023 All Rights Reserved.<strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>