<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">WITHDRAWAL TOKEN MANAGEMENT</h1>
    </div>

    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="row nlp">
                        <div class="col-5">
                            <label for="">Total Withdrawal Fiero Amount</label>
                        </div>
                        <div class="col-1">:</div>
                        <div class="col-5 d-flex">
                            <p class="amt">{{userBalance}}</p>
                        </div>

                        <!-- <div class="col-5">
                            <label for="">Pending Requested Fiero</label>
                        </div>
                        <div class="col-1">:</div>
                        <div class="col-5 d-flex">
                            <p class="amt">{{userDetailData?.pendingClaimAmount}}</p>
                        </div> -->
                    </div>
                    <div class="tab-pane1">
                        <div class="user-main-head sec_head_new sec_head_new_after">
                            <form [formGroup]="userForm">
                                <div class="row form-group">
                                    <div class="col-lg-3 col-md-4 col-sm-6 ">
                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center"
                                                    style="margin-left: 5px!important">From Date </span>

                                                <input type="date" class="form-control form" onkeydown="return false"
                                                    formControlName="startdate" [max]="today">
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">

                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center">To Date</span>
                                                <input type="date" class="form-control form" onkeydown="return false"
                                                    [readonly]="!this.userForm.controls['startdate'].value"
                                                    [min]="userForm.controls['startdate'].value"
                                                    formControlName="enddate">
                                            </div>
                                        </div>

                                    </div>
                                    <br>

                                    <div class="col-lg-3 col-md-4 col-sm-6" style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group w-20">

                                                <input type="datetime" class="form-control w-75"
                                                    placeholder="Enter Email" formControlName="searchText">

                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-4 col-sm-6" style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group w-20">
                                                <button type="button" class="btn  btn-theme cus-mr"
                                                    (click)="exportAsXLSX()">EXPORT AS EXCEL</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6" style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group w-20">
                                                <button type="submit" class="btn  btn-theme cus-mr"
                                                    (click)="pageNumber = 1;coinList()">Apply</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6" style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group w-20">
                                                <button type="button" class="btn  btn-theme" (click)="reset()">Clear
                                                </button>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="row col-md-12 justify-content-end">

                                        <div class="d-flex ">
                                            <!-- <div class=" " style="margin-top:26px;">
                                                <div class="serch-boxuser">
                                                    <div class="input-group filter_search_group">
                                                        <div class="text-left" style="margin-left: 1%">
                                                            <button type="button" class="btn  btn-theme cus-mr"
                                                                (click)="pageNumber = 1;newValue('REWARDED')">REWARDED</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->

                                        </div>



                                    </div>

                                </div>

                            </form>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="user list">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th scope="col">S.No.
                                        </th>
                                        <th scope="col">Username </th>
                                        <th scope="col">Email Id &nbsp;<i
                                            class="fa fa-sort" (click)="sortAgent('EMAIL')" aria-hidden="true"
                                            style="cursor: pointer;"></i></th>
                                        <th scope="col">Fiero Amount</th>
                                        <th scope="col">Fiero Price</th>
                                        <th scope="col">Claim Address</th>
                                        <th scope="col">Transaction Hash</th>
                                        <th scope="col">Txn. Time
                                            &nbsp;<i
                                                class="fa fa-sort" (click)="sortAgent('DATE')" aria-hidden="true"
                                                style="cursor: pointer;"></i>
                                        </th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of coinData | paginate: { 
                                                            itemsPerPage: itemsPerPage,
                                                            currentPage: pageNumber,
                                                            totalItems: totalRecords };let i = index">
                                        <td>{{itemsPerPage*(this.pageNumber-1)+i+1}}</td>
                                        <td>{{item?.userName || '--'}}</td>
                                        <td>{{item?.userEmail || '--'}}</td>
                                        <td>{{item?.amount || '--'}}</td>
                                        <td>{{item?.lastFieroPrice || '--'}}</td>
                                        <td>
                                            <div style="display: inline-block; margin-right: 5px; ">
                                                {{shortAddress(item?.address)}}
                                            </div><i class="fa fa-clone" (click)="copyToClipboard(item.address)"
                                                aria-hidden="true"></i>
                                        </td>
                                       
                                        <td>
                                            <div style="display: inline-block; margin-right: 5px; ">
                                                <a class="txnhash"
                                                (click)="item?.txnHashes && item?.txnHashes?.length && transactionHash(item?.txnHashes)">{{item?.txnHashes
                                                && item?.txnHashes?.length && shortAddress(item?.txnHashes[0]?.txnHash) || '--'}}</a>
                                            </div><i class="fa fa-clone" *ngIf="item?.txnHashes
                                            && item?.txnHashes?.length" (click)="item?.txnHashes
                                            && item?.txnHashes?.length && copyToClipboard(item?.txnHashes[0]?.txnHash)"
                                                aria-hidden="true"></i>
                                        </td>

                                        <td>{{(item?.updateDate && isValidDate(item?.updateDate) && item?.updateDate |
                                            date :'medium') || '--'}}</td>
                                        <td>{{item?.status || '--'}} </td>
                                    </tr>
                                    <tr *ngIf="coinData?.length==0">
                                        <td colspan="8" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5 style="margin: auto; text-align: center;">No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                            *ngIf="totalRecords > 10">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
<footer>
    <div class="container-fluid">
        <p class="copyright">FIERES ICO Crypto © 2023 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer> -->


<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="txnModal">
    <div class="modal-dialog">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Transactions Hash</h3>

                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100 global-table">
                                <div class="table-responsive">
                                    <table class="table table-bordered" aria-describedby="user list">
                                        <thead>
                                            <tr class="no_wrap_th">
                                                <th scope="col">S.No.
                                                </th>
                                                <th scope="col">Contract Address </th>
                                                <th scope="col">Claim Hash </th>
                                                <th scope="col">Transaction Hash</th>
                                                <th scope="col">Amount</th>
                                           
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of txnArray ;let i = index">
                                                <td>{{i+1}}</td>
                                                
                                                <td>
                                                    <div style="display: inline-block; margin-right: 5px; ">
                                                        {{item?.contractAddress && shortAddress(item?.contractAddress) || '--'}}
                                                    </div><i class="fa fa-clone" *ngIf="item?.contractAddress" (click)="copyToClipboard(item.contractAddress)"
                                                        aria-hidden="true"></i>
                                                </td>
                                                <td>
                                                    <div style="display: inline-block; margin-right: 5px; ">
                                                        {{item?.claimHash && shortAddress(item?.claimHash) || '--'}}
                                                    </div><i class="fa fa-clone" *ngIf="item?.claimHash" (click)="copyToClipboard(item.claimHash)"
                                                        aria-hidden="true"></i>
                                                </td>
                                                <td>
                                                    <div style="display: inline-block; margin-right: 5px; ">
                                                        {{item?.txnHash && shortAddress(item?.txnHash) || '--'}}
                                                    </div><i class="fa fa-clone" *ngIf="item?.txnHash" (click)="copyToClipboard(item.txnHash)"
                                                        aria-hidden="true"></i>
                                                </td>
                                                <td>
                                                    <div style="display: inline-block; margin-right: 5px; ">
                                                        {{item?.amount  || '--'}}
                                                    </div>
                                                </td>
                                             
                                               
                                             
                                            </tr>
                                            <tr *ngIf="txnArray?.length==0">
                                                <td colspan="8" vertical-align="middle">
                                                    <div class="no-record">
                                                        <div class="no-recordin">
                                                            <H5 style="margin: auto; text-align: center;">No record
                                                                found</H5>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->

<!-- block_modal Start -->
<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Block User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to block this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- active Start -->
<div class="modal fade global-modal reset-modal" id="active">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Active User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to active this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- accept token modal -->

<!-- appraved_modal Start -->
<div class="modal fade global-modal reset-modal" id="approveModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Approve</h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <!-- <p style="font-size: 25px;">Are you sure you want to approve kyc with data entered?</p> -->
                                <p style="font-size: 25px;">Are you sure you want to accept this token?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2 " style="width: 25%;"
                                        (click)="approveKyc()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>