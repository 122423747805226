import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/provider/main.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare var $
@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
  editForm: FormGroup
  editImage: any;
  userDetail: any;
  date: any = "2005-12-31";
  mydate = new Date().toISOString().slice(8, 10);
  newDay = parseInt(this.mydate) - 1;
  qrCode: any
  secretKey: any
  googleAuth: any = "";
  ipAddress: string;

  constructor(private router: Router, public service: MainService) { }

  ngOnInit() {
    /*  this.date=new Date() */
    /*  console.log('hdhfd', this.date); */
    //  this.date= new Date().toISOString().slice(0,8)+this.newDay;
    this.myProfile()
    this.editForm = new FormGroup({
      'name': new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/i)]),
      // 'gender': new FormControl('',[Validators.required, Validators.pattern(/^[a-zA-Z ]*$/i)]),
      'DOB': new FormControl('', [Validators.required]),
      'email': new FormControl('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,9}|[0-9]{1,3})(\]?)$/i)]),
      'phone': new FormControl('', [Validators.required, Validators.pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)]),
      'address': new FormControl('', Validators.required),
      'securityFA': new FormControl(''),
    })
    this.getIP()
  }

  myProfile() {
    var url = 'account/my-account';
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.userDetail = res['data'];
        this.editImage = this.userDetail.imageUrl;
        this.service.twoFA.next(res['data']['twoFaType'])
        this.editForm.patchValue({
          'name': this.userDetail.firstName,
          'email': this.userDetail.email,
          'phone': this.userDetail.phoneNo,
          'address': this.userDetail.address,
          // 'gender': this.userDetail.gender,
          'DOB': this.userDetail.dob,
          'securityFA': this.userDetail.twoFaType
        })
      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Session Expired');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
      this.service.hideSpinner();
    })


  }
  getIP() {
    this.service.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }
  // Api of update profile
  updateProfile() {
    var apiReq = {
      "address": this.editForm.value.address,
      "dob": this.editForm.value.DOB,
      "firstName": this.editForm.value.name,
      "phoneNo": this.editForm.value.phone,
      "gender": this.editForm.value.gender,
      "email": this.editForm.value.email,
      "imageUrl": this.editImage ? this.editImage : this.userDetail.imageUrl,
    }
    this.service.showSpinner();
    this.service.post('account/profile-update', apiReq).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.service.toasterSucc('Profile Updated Successfully');
        this.router.navigate(['my-profile'])

      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  // Image Functionality Start Here
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.service.showSpinner();
    this.service.postApi('account/upload-file', fb).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == '200') {
        this.editImage = res['data'];
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  googleStatusCheck() {
    if (this.userDetail.twoFaType == "SKIP" ||
      this.userDetail.twoFaType == "NONE") {
      this.enableGoogleAuth()
    }
    else if (this.userDetail.twoFaType == 'GOOGLE') {
      this.openGoogleAuthModal()
    }
  }

  // Enable Google Auth Functionality
  enableGoogleAuth() {
    if (!this.userDetail.email) {
      return this.service.toasterErr('Please update your profile.')
    } else {
      if (
        this.userDetail.twoFaType == "SKIP" ||
        this.userDetail.twoFaType == "NONE"
      ) {
        this.service.showSpinner();
        this.service.get("account/google-auth").subscribe(
          (res) => {
            if (res["status"] == 200) {
              this.qrCode = res["data"]["qrCode"];
              this.secretKey = res["data"]["secretKey"];
              $("#googleAuth").modal({ backdrop: "static", keyboard: false });
              this.service.hideSpinner();
            }
          },
          (err) => {
            this.service.hideSpinner();
          }
        );
      } else {
        this.service.toasterErr("Disable SMS Auth First");
      }
    }
  }

  //Disable Google Security
  openGoogleAuthModal() {
    this.googleAuth = "";
    $("#googleAuthDisable").modal({ backdrop: "static" });
  }
  disableTwoFA() {
    var apireq = {
      code: this.googleAuth, //this.authCode,
      ipAddress: this.ipAddress,
      source: "WEB",
    };
    this.service.showSpinner();
    this.service.postApi("account/twoFa-disable", apireq).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          $("#googleAuthDisable").modal("hide");
          this.myProfile();
          this.googleAuth = "";
          this.service.toasterSucc("Google Auth Disabled Successfully");
        } else {
          this.service.toasterErr("Wrong Google Authentication Code");
          this.googleAuth = "";
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.toasterErr(err["message"]);
      }
    );
  }
  verifyGoogleAuth() {
    var url = "account/verify-google-code";
    var apireq = {
      code: this.googleAuth,
      secretKey: this.secretKey,
      ipAddress: this.ipAddress,
      source: "WEB",
    };
    this.service.showSpinner();
    this.service.post(url, apireq).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.qrCode = "";
          this.secretKey = "";
          this.googleAuth = "";
          this.myProfile();
          this.service.toasterSucc("Google Auth Enabled Successfully");
          $("#googleAuth").modal("hide");
        } else {
          this.service.toasterErr("Wrong Google Authentication Code");
          this.googleAuth = "";
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.toasterErr("Something Went Wrong");
      }
    );
  }
  /** Function to restrict space */
  restrictSpace(event) {
    var k = event.charCode;
    if (k === 32) return false;
  }

  /** Function to restrict character */
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === "Backspace") k = 8;
    if ((k >= 48 && k <= 57) || k == 8 || k == 46) return true;
    else return false;
  }
  copyToClipboard(item) {
    this.service.toasterSucc("Secret key copied");
    document.addEventListener("copy", (e: ClipboardEvent) => {
      e.clipboardData.setData("text/plain", item);
      e.preventDefault();
      document.removeEventListener("copy", null);
    });
    document.execCommand("copy");
  }
  removeToken() { }
}
